/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import { MaterialSymbolsAddChartOutline } from "@/components/icons/MaterialSymbolsAddChartOutline";
import { Label } from "@/components/ui/label";
import { addAccionToSiniestro } from "@/services/siniestroService";
import { ReloadIcon } from "@radix-ui/react-icons";

interface AddGastoDialogProps {
  onSave: (newData: { text: string; value: number }) => Promise<void>;
  siniestroId: number;
  disabled?: boolean;
}

export function AddGastoDialog({
  onSave,
  siniestroId,
  disabled,
}: AddGastoDialogProps) {
  const [textInput, setTextInput] = useState("");
  const [valueInput, setValueInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    const newData = {
      text: textInput,
      value: Number(valueInput),
    };
    try {
      await addAccionToSiniestro(siniestroId, 52, null, null);
      await onSave(newData);
      setIsOpen(false);
    } catch (error) {
    } finally {
      setIsSaving(false);
    }
  };

  const handleTextKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = event.charCode;
    // Allow letters and space only
    if (
      !(
        (
          (charCode >= 65 && charCode <= 90) || // A-Z
          (charCode >= 97 && charCode <= 122) || // a-z
          charCode === 32
        ) // space
      )
    ) {
      event.preventDefault();
    }
  };

  const handleValueKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const charCode = event.charCode;
    // Allow numbers only
    if (!(charCode >= 48 && charCode <= 57)) {
      event.preventDefault();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          variant="green"
          onClick={() => setIsOpen(true)}
          disabled={disabled}
        >
          <MaterialSymbolsAddChartOutline className="w-5 h-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Agregar gasto</DialogTitle>
          <DialogDescription>
            Ingresa los detalles del nuevo gasto y haz clic en guardar.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Label htmlFor="textInput">Tipo de gasto</Label>
          <Input
            id="textInput"
            value={textInput}
            onChange={(e) => {
              setTextInput(e.target.value);
            }}
            onKeyPress={handleTextKeyPress}
          />
          <Label htmlFor="valueInput">Valor</Label>
          <Input
            id="valueInput"
            type="number"
            value={valueInput}
            onChange={(e) => {
              setValueInput(e.target.value);
            }}
            onKeyPress={handleValueKeyPress}
          />
        </div>
        <DialogFooter>
          <Button onClick={handleSave} disabled={isSaving}>
            {isSaving ? (
              <>
                <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                Guardando...
              </>
            ) : (
              "Guardar"
            )}
          </Button>
          <DialogClose asChild>
            <Button variant="secondary" disabled={isSaving}>
              Cerrar
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
