import { type ColumnDef } from "@tanstack/react-table";
import { Badge } from "../ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import { MoreHorizontal } from "lucide-react";
import { updateGastoStatus } from "@/services/gastosService";
import { addAccionToSiniestro } from "@/services/siniestroService";
import { toast } from "sonner";
import { Checkbox } from "../ui/checkbox";

export const GastosProveedoresColumns = (
  updateGasto: (gastoId: number, newStatus: boolean) => void
): Array<ColumnDef<GastoProveedor>> => {
  const handleStatusChange = async (
    gastoId: number,
    newStatus: boolean,
    siniestroId: number,
    idGasto: number
  ) => {
    try {
      // Actualizar el estado del gasto
      await updateGastoStatus(gastoId, newStatus);

      // Registrar la acción
      const idActionType = newStatus ? 59 : 60; // 59: pagado, 60: pendiente

      await addAccionToSiniestro(
        siniestroId,
        idActionType,
        null, // userAssignedId
        idGasto // itemId
      );

      // Actualizar el estado local
      updateGasto(gastoId, newStatus);

      toast.success(
        newStatus
          ? "Gasto marcado como pagado correctamente"
          : "Gasto marcado como pendiente correctamente"
      );
    } catch (error) {
      console.error("Error completo:", error);
      toast.error("Error al actualizar el estado");
    }
  };

  return [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "companyIncidentNumber",
      header: "Nº Siniestro Compañía",
      cell: ({ row }) => {
        const incidentId = row.original.companyIncidentNumber;
        const type = row.original.type;
        return (
          <div>
            {incidentId}{" "}
            {type === "G" && <Badge variant="outline">Garantía</Badge>}
          </div>
        );
      },
    },
    {
      accessorKey: "text",
      header: "Descripción",
    },
    {
      accessorKey: "value",
      header: "Monto",
      cell: (info) => {
        const value = info.getValue() as number;
        return new Intl.NumberFormat("es-AR", {
          style: "currency",
          currency: "ARS",
        }).format(value);
      },
    },
    {
      accessorKey: "gastoInsertedAt",
      header: "Fecha de Ingreso",
      cell: (info) => {
        const dateValue = info.getValue() as string;
        const date = new Date(dateValue);
        return new Intl.DateTimeFormat("es-AR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(date);
      },
    },
    {
      accessorKey: "is_paid",
      header: "Estado",
      cell: (info) => {
        const isPaid = info.getValue() as boolean;

        return isPaid ? (
          <Badge variant="success">Pagado</Badge>
        ) : (
          <Badge variant="destructive">Pendiente</Badge>
        );
      },
    },
    {
      accessorKey: "accion_username",
      header: "Usuario",
      cell: ({ row }) => {
        const creador = row.original.creator_username;
        const proveedor = row.original.accion_username;
        return (
          <div className="flex flex-col">
            <span className="font-medium">{proveedor || "Sin asignar"}</span>
            {creador && (
              <span className="text-xs text-muted-foreground">
                Creado por: {creador}
              </span>
            )}
          </div>
        );
      },
    },
    {
      id: "actions",
      header: "Acciones",
      cell: ({ row }) => {
        const gasto = row.original;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Abrir menú</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onSelect={() =>
                  handleStatusChange(
                    gasto.gasto_proveedor_id,
                    true,
                    gasto.incident_id,
                    gasto.id_gasto
                  )
                }
              >
                Marcar como pagado
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() =>
                  handleStatusChange(
                    gasto.gasto_proveedor_id,
                    false,
                    gasto.incident_id,
                    gasto.id_gasto
                  )
                }
              >
                Marcar como pendiente
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
};
