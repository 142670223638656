import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { SolarLogout2Bold } from "./icons/SolarLogout2Bold";
import { Button } from "./ui/button";
import { PhFirstAidKitBold } from "./icons/PhFirstAidKitBold";
import { MaterialSymbolsSettingsAccountBox } from "./icons/MaterialSymbolsSettingsAccountBox";
import { ModeToggle } from "./mode-toggle";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "./ui/dropdown-menu";
import {
  RiMailLine,
  RiNotification3Line,
  RiSearchLine,
  RiTimerLine,
} from "react-icons/ri";
import useNotificationStore from "@/stores/notificationStore";
import { FaStore } from "react-icons/fa";
import { HiChartPie } from "react-icons/hi";
import isotipo from "@/logo/isotipo.png";

const links = [
  {
    title: "Siniestros",
    href: "/siniestros",
    roles: [2, 3, 4, 5, 6, 7, 15, 12, 11, 16, 14, 13],
  },
  { title: "Búsqueda Stros.", href: "/siniestros/busqueda", roles: [7] },
  { title: "Parametría", href: "/list", roles: [2] },
  { title: "Facturación", href: "/facturacion", roles: [2] },
  { title: "Dashboard", href: "/dashboard", roles: [2] },
  { title: "Estadísticas", href: "/dashboard/stats", roles: [2, 7] },
  { title: "Proveedores", href: "/proveedores", roles: [2] },
  { title: "Stros. prescritos", href: "/siniestros/antiguos", roles: [2] },
];

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { roles, isAuthenticated, logout, username, isAuthChecked } = useAuth();
  const {
    unreadCount,
    unreadMentionsCount,
    startFetchingUnreadCount,
    stopFetchingUnreadCount,
    resetUnreadCount,
    fetchInitialUnreadCount,
  } = useNotificationStore();
  const shouldShowMessages = !roles.some((role) => [7, 11].includes(role));

  // Estado local para rastrear si ya iniciamos las notificaciones
  const [notificationsInitialized, setNotificationsInitialized] =
    useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const initNotifications = async () => {
      // Solo proceder si todas las condiciones son verdaderas y no hemos inicializado aún
      if (
        isAuthChecked &&
        isAuthenticated &&
        username &&
        !notificationsInitialized
      ) {
        // Esperar un momento para asegurarnos de que la autenticación esté completamente establecida
        timeoutId = setTimeout(async () => {
          try {
            await fetchInitialUnreadCount(username);
            startFetchingUnreadCount(username);
            setNotificationsInitialized(true);
          } catch (error) {
            console.error("Error initializing notifications:", error);
          }
        }, 1000); // Esperar 1 segundo después de que la autenticación esté lista
      }
    };

    initNotifications();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      stopFetchingUnreadCount();
    };
  }, [isAuthChecked, isAuthenticated, username, notificationsInitialized]);

  // Resetear el estado de inicialización cuando el usuario cambia
  useEffect(() => {
    if (!isAuthenticated) {
      setNotificationsInitialized(false);
    }
  }, [isAuthenticated]);

  const handleLogout = () => {
    logout();
    resetUnreadCount();
    navigate("/");
  };

  const handleLogin = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("username");
    localStorage.removeItem("state");
    localStorage.removeItem("roles");
    navigate("/");
  };

  const filteredLinks = links.filter((link) => {
    return roles?.some((role) => link.roles.includes(role));
  });

  return (
    <div className="fixed top-0 w-full bg-opacity-60 backdrop-blur-md border-b border-gray-200 dark:border-gray-700 flex justify-between items-center px-4 py-2 z-10">
      <div className="flex items-center space-x-4">
        <Link
          to="/siniestros"
          className="flex items-center space-x-2 no-underline text-gray-800 dark:text-gray-200 hover:no-underline hover:text-gray-800 dark:hover:text-gray-200 focus:no-underline focus:text-gray-800 dark:focus:text-gray-200"
        >
          <img
            src={isotipo}
            alt="Tu Service"
            className="w-10 h-10 object-contain"
          />
          <span className="text-sm font-semibold">Tu Service</span>
        </Link>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button>Menú</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {filteredLinks.map((link) => (
              <DropdownMenuItem key={link.title} asChild>
                <Link
                  to={link.href}
                  className={`flex items-center no-underline text-gray-800 dark:text-gray-200 text-sm ${
                    location.pathname === link.href
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  onClick={(e) => {
                    if (location.pathname === link.href) {
                      e.preventDefault();
                    }
                  }}
                >
                  {getIcon(link.title)}
                  {link.title}
                </Link>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        <ModeToggle />
      </div>

      <div className="flex items-center space-x-4">
        {isAuthenticated ? (
          <>
            <span className="hidden sm:flex items-center mr-4">
              Bienvenido, {username} <span className="ml-1 text-xl">👋</span>
            </span>

            <Link to="/notificaciones" className="relative">
              {unreadCount > 0 && (
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full px-1.5 py-0.5">
                  {unreadCount}
                </span>
              )}
              <RiNotification3Line className="w-6 h-6 text-gray-800 dark:text-gray-200" />
            </Link>

            {shouldShowMessages && (
              <Link to="/notificaciones" className="relative">
                {unreadMentionsCount > 0 && (
                  <span className="absolute -top-1 -right-1 bg-blue-500 text-white text-xs rounded-full px-1.5 py-0.5">
                    {unreadMentionsCount}
                  </span>
                )}
                <RiMailLine className="w-6 h-6 text-gray-800 dark:text-gray-200" />
              </Link>
            )}

            <Button
              variant="link"
              asChild
              className="text-sm text-gray-800 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400"
            >
              <a
                href="https://tuservicesrl.net/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <FaStore className="mr-1" />
                División Electro
              </a>
            </Button>
            <Button
              onClick={handleLogout}
              variant="destructive"
              className="flex items-center"
            >
              <span className="hidden sm:inline">Cerrar sesión</span>
              <SolarLogout2Bold className="w-4 h-4 ml-2" />
            </Button>
          </>
        ) : (
          <Button
            onClick={handleLogin}
            variant="default"
            className="flex items-center"
          >
            <span>Iniciar sesión</span>
          </Button>
        )}
      </div>
    </div>
  );
};

const getIcon = (title: string) => {
  switch (title) {
    case "Siniestros":
      return <PhFirstAidKitBold className="mr-2" />;
    case "Parametría":
      return <MaterialSymbolsSettingsAccountBox className="mr-2" />;
    case "Dashboard":
      return <MaterialSymbolsSettingsAccountBox className="mr-2" />;
    case "Facturación":
      return <PhFirstAidKitBold className="mr-2" />;
    case "Proveedores":
      return <PhFirstAidKitBold className="mr-2" />;
    case "Stros. prescritos":
      return <RiTimerLine className="mr-2" />;
    case "Estadísticas":
      return <HiChartPie className="mr-2" />;
    case "Búsqueda Stros.":
      return <RiSearchLine className="mr-2" />;
    default:
      return null;
  }
};

export default Navbar;
