import React, { useCallback, useEffect, useState } from "react";
import Spinner from "../../ui/spinner";
import { Card, CardContent } from "../../ui/card";
import { EditBienDialog } from "../dialogs/EditBienDialog";
import { EditObservacionesDialog } from "../dialogs/EditObservacionesDialog";
import { PiImagesSquareBold } from "react-icons/pi";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Skeleton } from "../../ui/skeleton";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useEmblaCarousel from "embla-carousel-react";
import { Separator } from "@/components/ui/separator";
import { formattedValueARS } from "./formattedValue";
import { EditDetailsDialog } from "../dialogs/EditDetailsDialog";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { AddBienDialog } from "../dialogs/AddBienDialog";
import { fetchImagesByBienId } from "@/services/bienesService";
import ImageDialog from "./ImageDialog";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from "@/components/ui/dialog";
import { toast } from "sonner";
import { apiFetch, fetchBienById } from "@/services/siniestroService";
import { useAuth } from "@/contexts/AuthContext";
import { EditOtherObservacionesDialog } from "../dialogs/EditOtherObservationsDialog";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { RiDeleteBinLine } from "react-icons/ri";
import { AddInformeTecnicoDialog } from "./AddInformeTecnicoDialog";

const resolutionTypeMap: Record<
  string,
  | "Rechazado"
  | "Liquidado"
  | "Reparado"
  | "Reingreso por garantía"
  | "Desistido"
> = {
  1: "Rechazado",
  2: "Liquidado",
  3: "Reparado",
  4: "Reingreso por garantía",
  5: "Desistido",
};

interface BienesSiniestroProps {
  bienes: Bien[];
  isLoadingBienes: boolean;
  onUpdateBien: (bienId: number, newValues: SaveBienValues) => Promise<void>;
  onResolutionChange: (
    bienId: number,
    newResolutionId: number
  ) => Promise<void>;
  onSaveObservaciones: (bienId: number, observaciones: string) => Promise<void>;
  updatingBien: Record<number, boolean>;
  causas: Array<{ id: number; name: string }>;
  resoluciones: Resolucion[];
  tiposPago: TipoPago[];
  onTipoPagoChange: (bienId: number, newTipoPagoId: number) => Promise<void>;
  onDetailsUpdated: (bienId: number, updatedDetails: any) => void;
  siniestroId: number;
  onAddBien: (nuevoBien: Bien) => void;
  onInstanceChange: (bienId: number, newInstanceId: number) => Promise<void>;
  instancias: Instancia[];
  updatingBienInstance: Record<number, boolean>;
  instanciaActual: Record<number, number>;
  resolucionActual: Record<number, number>;
  onOtherObservationsChange: (
    bienId: number,
    observaciones: string
  ) => Promise<void>;
  sumaAsegurada: number;
  coberturas?: Cobertura[];
  disabled?: boolean;
  onSiniestroUpdated: (siniestro: Siniestro) => void;
  siniestro: Siniestro;
  onAddIngreso: (newData: { text: string; value: number }) => Promise<void>;
}

export const BienesSiniestro: React.FC<BienesSiniestroProps> = ({
  bienes,
  isLoadingBienes,
  onUpdateBien,
  onResolutionChange,
  updatingBien,
  resoluciones,
  onSaveObservaciones,
  onOtherObservationsChange,
  tiposPago,
  onTipoPagoChange,
  causas,
  onDetailsUpdated,
  siniestroId,
  onInstanceChange,
  instancias,
  updatingBienInstance,
  instanciaActual,
  resolucionActual,
  sumaAsegurada,
  coberturas,
  disabled = false,
  onSiniestroUpdated,
  siniestro,
  onAddIngreso,
}) => {
  const causaMap: Record<string, string> = causas
    ? causas.reduce<Record<string, string>>((acc, causa) => {
        acc[causa.id.toString()] = causa.name;
        return acc;
      }, {})
    : {};

  const [selectedBien, setSelectedBien] = useState<Bien | null>(null);
  const [images, setImages] = useState<{ bienId: number; images: string[] }[]>(
    []
  );
  const [isOpen, setIsOpen] = useState(false);
  const { roles } = useAuth();
  const hasRole11 = roles.includes(11);
  const hasRole2 = roles.includes(2);
  const hasRole7 = roles.includes(7);
  const [localBienes, setLocalBienes] = useState<Bien[]>(bienes);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [bienToDelete, setBienToDelete] = useState<Bien | null>(null);
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false, duration: 20 });
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [imagesLoading, setImagesLoading] = useState(true);
  const [openItems, setOpenItems] = useState<Set<number>>(new Set());

  const toggleAccordionItem = (bienId: number) => {
    setOpenItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(bienId)) {
        newSet.delete(bienId);
      } else {
        newSet.add(bienId);
      }
      return newSet;
    });
  };

  useEffect(() => {
    if (!emblaApi) return;

    emblaApi.on("select", () => {
      setActiveIndex(emblaApi.selectedScrollSnap());
    });
  }, [emblaApi]);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  const onDotButtonClick = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("select", onSelect);
    emblaApi.on("reInit", onSelect);
  }, [emblaApi, onSelect]);

  useEffect(() => {
    if (localBienes.length === 0) {
      setLocalBienes(bienes);
    }
  }, [bienes, localBienes.length, localBienes]);

  useEffect(() => {}, [localBienes]);

  useEffect(() => {
    setLocalBienes(bienes);
  }, [bienes]);

  useEffect(() => {
    const loadImages = async () => {
      setImagesLoading(true);
      const allImages = await Promise.all(
        Array.from(openItems).map(async (bienId) => {
          const images = await fetchImagesByBienId(bienId);
          return { bienId, images };
        })
      );
      setImages(allImages);
      setImagesLoading(false);
    };

    if (openItems.size > 0) {
      loadImages();
    }
  }, [openItems]);

  const handleAddBien = async (nuevoBien: Bien) => {
    try {
      // Hacer una llamada para obtener los detalles completos del bien recién creado
      const bienCompleto = await fetchBienById(nuevoBien.id);

      // Crear un objeto con los campos mapeados
      const bienMapeado = {
        ...bienCompleto,
        brandName: bienCompleto.brand_name,
        itemType: bienCompleto.item_type_name,
        segmentName: bienCompleto.segment_name,
        causeName: bienCompleto.cause_name,
        tipoPagoName: bienCompleto.tipo_pago_name,
        resolutionName: bienCompleto.resolution_name,
      };

      setLocalBienes((prevBienes) => [...prevBienes, bienMapeado]);
    } catch (error) {
      console.error("Error fetching bien details:", error);
    }
  };

  const handleViewImages = async (bien: Bien) => {
    const fetchedImages = await fetchImagesByBienId(bien.id);
    setSelectedBien(bien);
    setImages((prevImages) => [
      ...prevImages.filter((img) => img.bienId !== bien.id),
      { bienId: bien.id, images: fetchedImages },
    ]);
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
    setSelectedBien(null);
  };

  const refreshImages = async () => {
    if (selectedBien) {
      const fetchedImages = await fetchImagesByBienId(selectedBien.id);
      setImages((prevImages) =>
        prevImages.map((imageObj) =>
          imageObj.bienId === selectedBien.id
            ? { bienId: selectedBien.id, images: fetchedImages }
            : imageObj
        )
      );
    }
  };

  const handleSiniestroPartialUpdate = (
    updatedSiniestro: Partial<Siniestro>
  ) => {
    // Combina el siniestro existente con las actualizaciones parciales
    const fullUpdatedSiniestro = {
      ...siniestro,
      ...updatedSiniestro,
    };
    onSiniestroUpdated(fullUpdatedSiniestro);
  };

  const confirmDeleteBien = (bien: Bien) => {
    setBienToDelete(bien);
    setShowDeleteDialog(true);
  };

  const handleDeleteBien = async () => {
    if (!bienToDelete) return;

    try {
      const response = await apiFetch(`/api/bienes/${bienToDelete.id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        toast.success(`Bien eliminado correctamente`);
        setShowDeleteDialog(false);
        setBienToDelete(null);
        setLocalBienes(
          localBienes.filter((bien) => bien.id !== bienToDelete.id)
        );
      } else {
        const result = await response.text();
        toast.error(result || "Error deleting bien");
      }
    } catch (error) {
      toast.error("Error deleting bien");
    }
  };

  const handleDetailsUpdated = (bienId: number, updatedDetails: any) => {
    onDetailsUpdated(bienId, updatedDetails);
    setLocalBienes((prevBienes) =>
      prevBienes.map((bien) =>
        bien.id === bienId ? { ...bien, ...updatedDetails } : bien
      )
    );
  };

  return (
    <CardContent className="p-4">
      <Card className="dark:bg-gray-700 p-4 rounded-lg shadow">
        <h3 className="font-semibold text-gray-700 dark:text-gray-300 mb-4">
          Bienes afectados:
        </h3>
        {isLoadingBienes ? (
          <Spinner />
        ) : (
          <Accordion type="multiple" className="w-full">
            {localBienes.map((bien) => {
              const tipoResolucion = resolutionTypeMap[
                bien.resolution_id?.toString()
              ] as
                | "Rechazado"
                | "Liquidado"
                | "Reparado"
                | "Reingreso por garantía"
                | "Desistido";
              const valorReclamado = bien.claimedValue ?? 0;
              const valorAReconocer = bien.recognizedValue ?? 0;
              const valorReparado = bien.repairedRecognizedValue ?? 0;
              const causaNombre =
                causaMap[bien.cause_id?.toString() || ""] || "Desconocido";

              const currentInstance =
                instanciaActual[bien.id] || bien.instance_id;
              const currentResolution =
                resolucionActual[bien.id] || bien.resolution_id;

              return (
                <div key={bien.id} className="mb-4">
                  <AccordionItem
                    key={bien.id}
                    value={`bien-${bien.id}`}
                    className="mb-4 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg shadow-md"
                  >
                    <AccordionTrigger
                      onClick={() => toggleAccordionItem(bien.id)}
                      className="p-4 font-semibold bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-lg transition duration-300"
                    >
                      {bien.itemType} - {bien.brandName}
                    </AccordionTrigger>
                    <AccordionContent className="p-4 bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 border-t border-gray-300 dark:border-gray-600 rounded-lg">
                      <div className="flex justify-between items-center">
                        <div className="flex-1 min-w-0">
                          <div className="flex items-center space-x-2">
                            <p className="text-sm text-gray-600 dark:text-gray-300">
                              <span className="font-semibold">
                                {bien.itemType}
                              </span>{" "}
                              ({bien.segmentName})
                            </p>
                            <div className="flex items-center">
                              {!hasRole7 && (
                                <EditDetailsDialog
                                  bienId={bien.id}
                                  segmentName={bien.segmentName}
                                  brandName={bien.brandName}
                                  model={bien.model}
                                  serial={bien.serial}
                                  coveredDetails={bien.coveredDetails}
                                  brand={bien.brand_id?.toString() || ""}
                                  segmentId={bien.segment_id}
                                  itemType={bien.itemType}
                                  causeId={bien.cause_id}
                                  causaNombre={causaNombre}
                                  accesories={bien.accesories || undefined}
                                  siniestroId={siniestroId}
                                  onDetailsSaved={handleDetailsUpdated}
                                  disabled={disabled}
                                />
                              )}
                              {hasRole2 && (
                                <Button
                                  variant="destructive"
                                  size="icon"
                                  onClick={() => confirmDeleteBien(bien)}
                                  className="ml-2"
                                >
                                  <RiDeleteBinLine />
                                </Button>
                              )}
                            </div>
                          </div>
                          <p className="text-gray-700 dark:text-gray-300 mt-2">
                            Marca: {bien.brandName}
                          </p>
                          <p className="text-gray-700 dark:text-gray-300 mt-2">
                            Modelo: {bien.model}
                          </p>
                          <p className="text-gray-700 dark:text-gray-300 mt-2">
                            Nº de serie: {bien.serial}
                          </p>
                          <p className="text-gray-700 dark:text-gray-300 mt-2">
                            Cobertura de póliza: {bien.coveredDetails}
                          </p>
                          <p className="text-gray-700 dark:text-gray-300 mt-2">
                            Accesorios: {bien.accesories}
                          </p>
                          <Separator className="my-4" />
                          <p className="text-gray-700 dark:text-gray-300 mt-2">
                            Motivo de denuncia: {causaNombre}
                          </p>
                          {bien.receiptNumber && (
                            <p className="text-gray-700 dark:text-gray-300 mt-2">
                              Orden de trabajo Nº: {bien.receiptNumber}
                            </p>
                          )}
                          {bien.deliveryNumber && (
                            <p className="text-gray-700 dark:text-gray-300 mt-2">
                              Orden de entrega Nº: {bien.deliveryNumber}
                            </p>
                          )}
                        </div>
                        <Separator orientation="vertical" />
                        <div className="flex-1 pl-4">
                          {/* Carrusel de Imágenes */}
                          <div className="flex justify-center mb-4">
                            <Button
                              variant="outline"
                              onClick={() => handleViewImages(bien)}
                            >
                              <PiImagesSquareBold className="mr-2" />
                              Ver imágenes
                            </Button>
                          </div>
                          {images.length > 0 &&
                            (() => {
                              const bienImages = images.find(
                                (img) => img.bienId === bien.id
                              );

                              if (imagesLoading) {
                                return (
                                  <div className="max-w-xs mx-auto mb-4 mt-6 relative">
                                    <div className="h-32 flex items-center justify-center">
                                      <Skeleton className="h-full w-full rounded-lg" />
                                    </div>
                                    <div className="flex justify-between mt-2">
                                      <Skeleton className="h-8 w-8 rounded-full" />
                                      <Skeleton className="h-8 w-8 rounded-full" />
                                    </div>
                                    <div className="flex justify-center space-x-2 mt-2">
                                      <Skeleton className="h-2 w-2 rounded-full" />
                                      <Skeleton className="h-2 w-2 rounded-full" />
                                      <Skeleton className="h-2 w-2 rounded-full" />
                                    </div>
                                  </div>
                                );
                              }

                              if (
                                !bienImages ||
                                bienImages.images.length === 0
                              ) {
                                return (
                                  <p className="text-center text-gray-700 dark:text-gray-300 mt-4">
                                    No hay imágenes cargadas
                                  </p>
                                );
                              }

                              return (
                                <div className="max-w-xs mx-auto mb-4 mt-6 relative">
                                  <div
                                    className="embla overflow-hidden"
                                    ref={emblaRef}
                                  >
                                    <div className="embla__container flex">
                                      {bienImages.images.map((image, index) => (
                                        <div
                                          className={`embla__slide flex-[0_0_100%] min-w-0 ${
                                            index === activeIndex
                                              ? "embla__slide--active"
                                              : ""
                                          }`}
                                          key={index}
                                        >
                                          <a
                                            href={image}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src={image}
                                              alt={`Imagen ${index + 1}`}
                                              className="rounded-lg mx-auto max-h-[150px] w-auto"
                                            />
                                          </a>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <Button
                                    onClick={scrollPrev}
                                    disabled={prevBtnDisabled}
                                    className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-full"
                                    variant="outline"
                                    size="icon"
                                  >
                                    <ChevronLeft className="h-4 w-4" />
                                  </Button>
                                  <Button
                                    onClick={scrollNext}
                                    disabled={nextBtnDisabled}
                                    className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-full"
                                    variant="outline"
                                    size="icon"
                                  >
                                    <ChevronRight className="h-4 w-4" />
                                  </Button>
                                  <div className="embla__dots mt-2 flex justify-center space-x-2">
                                    {scrollSnaps.map((_, index) => (
                                      <Button
                                        key={index}
                                        onClick={() => onDotButtonClick(index)}
                                        className={`w-2 h-2 rounded-full ${
                                          index === selectedIndex
                                            ? "bg-blue-500"
                                            : "bg-gray-300"
                                        }`}
                                        variant="outline"
                                        size="sm"
                                      />
                                    ))}
                                  </div>
                                </div>
                              );
                            })()}
                        </div>
                      </div>

                      <div className="mt-8">
                        <Separator />
                      </div>

                      <div className="flex items-center justify-between mt-3">
                        {updatingBienInstance[bien.id] ? (
                          <Skeleton className="w-[230px] h/[20px] rounded-full" />
                        ) : (
                          <p className="text-gray-700 dark:text-gray-300">
                            Instancia actual: {bien.instanceName}
                          </p>
                        )}
                        {!hasRole7 && (
                          <div className="mx-1">
                            <Select
                              key={`instance-${bien.instance_id}-${currentInstance}`} // Agregamos key para forzar la actualización
                              defaultValue={
                                currentInstance?.toString() ??
                                bien.instance_id?.toString() ??
                                ""
                              }
                              onValueChange={async (newInstanceId) => {
                                await onInstanceChange(
                                  bien.id,
                                  parseInt(newInstanceId)
                                );
                              }}
                              disabled={
                                (currentInstance === 10 && !hasRole2) ||
                                disabled
                              }
                            >
                              <SelectTrigger className="ml-2 w/[240px]">
                                <SelectValue placeholder="Seleccionar" />
                              </SelectTrigger>
                              <SelectContent>
                                {instancias.map((instancia) => (
                                  <SelectItem
                                    key={instancia.id}
                                    value={instancia.id.toString()}
                                    disabled={hasRole11 && instancia.id === 10}
                                  >
                                    {instancia.name}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </div>
                        )}
                      </div>
                      <div className="flex items-center justify-between mt-3">
                        {updatingBien[bien.id] ? (
                          <Skeleton className="w/[230px] h/[20px] rounded-full" />
                        ) : (
                          <p className="text-gray-700 dark:text-gray-300">
                            Resolución actual: {bien.resolutionName}
                          </p>
                        )}
                        <div className="mx-1">
                          {!hasRole7 && (
                            <Select
                              defaultValue={
                                bien.resolution_id?.toString() ?? ""
                              }
                              onValueChange={async (newResId) => {
                                // Mantenemos la funcionalidad original
                                await onResolutionChange(
                                  bien.id,
                                  parseInt(newResId)
                                );

                                // Si es "Sin cobertura", actualizamos la instancia
                                if (parseInt(newResId) === 8) {
                                  await onInstanceChange(bien.id, 10);
                                  // Actualizamos el estado local de la instancia actual
                                  setLocalBienes((prevBienes) =>
                                    prevBienes.map((b) =>
                                      b.id === bien.id
                                        ? {
                                            ...b,
                                            instance_id: 10,
                                            instanceName: "Cerrado",
                                          }
                                        : b
                                    )
                                  );
                                }
                              }}
                              disabled={currentInstance === 10}
                            >
                              <SelectTrigger className="ml-2 w/[200px]">
                                <SelectValue placeholder="Seleccionar" />
                              </SelectTrigger>
                              <SelectContent>
                                {resoluciones.map((resolucion) => (
                                  <SelectItem
                                    key={resolucion.id}
                                    value={resolucion.id.toString()}
                                  >
                                    {resolucion.name}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center justify-between mt-3">
                        <p className="text-gray-700 dark:text-gray-300">
                          Tipo de pago actual: {bien.tipoPagoName}
                        </p>
                        <div className="mx-1">
                          {!hasRole7 && (
                            <Select
                              defaultValue={
                                bien.item_payment_type?.toString() ?? ""
                              }
                              onValueChange={async (newTipoPagoId) => {
                                await onTipoPagoChange(
                                  bien.id,
                                  parseInt(newTipoPagoId)
                                );
                              }}
                              disabled={
                                currentResolution !== 2 || // Deshabilitar si la resolución no es 'Liquidado'
                                currentInstance === 10 // Deshabilitar si la instancia es 'Cerrado'
                              }
                            >
                              <SelectTrigger className="ml-2 w/[200px]">
                                <SelectValue placeholder="Seleccionar" />
                              </SelectTrigger>
                              <SelectContent>
                                {tiposPago.map((tipoPago) => (
                                  <SelectItem
                                    key={tipoPago.id}
                                    value={tipoPago.id.toString()}
                                  >
                                    {tipoPago.name}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          )}
                        </div>
                      </div>

                      <div className="mt-4 mb-6">
                        <Separator />
                      </div>
                      <p className="text-gray-700 dark:text-gray-300 mt-2">
                        Valor reclamado:{" "}
                        {isNaN(bien.claimedValue ?? 0)
                          ? formattedValueARS(0)
                          : formattedValueARS(bien.claimedValue ?? 0)}
                      </p>
                      <p className="text-gray-700 dark:text-gray-300 mt-2">
                        Valor reconocido:{" "}
                        {isNaN(bien.recognizedValue ?? 0)
                          ? formattedValueARS(0)
                          : formattedValueARS(bien.recognizedValue ?? 0)}
                      </p>
                      <p className="text-gray-700 dark:text-gray-300 mt-2">
                        Valor reparado:{" "}
                        {isNaN(bien.repairedRecognizedValue ?? 0)
                          ? formattedValueARS(0)
                          : formattedValueARS(
                              bien.repairedRecognizedValue ?? 0
                            )}
                      </p>
                      <div className="mt-6 mb-6">
                        <Separator />
                      </div>
                      <p className="text-gray-700 dark:text-gray-300 mt-2">
                        Informe técnico: {bien.observations}
                      </p>
                      <p className="text-gray-700 dark:text-gray-300 mt-2">
                        Observaciones: {bien.otherObservations}
                      </p>
                      <div className="mt-6 mb-6">
                        <Separator />
                      </div>

                      {!hasRole7 && (
                        <EditBienDialog
                          bienId={bien.id}
                          tipoResolucion={tipoResolucion}
                          valorReclamado={
                            valorReclamado !== 0 ? valorReclamado : null
                          }
                          valorAReconocer={
                            valorAReconocer !== 0 ? valorAReconocer : null
                          }
                          valorReparado={
                            valorReparado !== 0 ? valorReparado : null
                          }
                          onSave={onUpdateBien}
                          onDetailsSaved={handleDetailsUpdated}
                          sumaAsegurada={sumaAsegurada}
                          coberturas={coberturas}
                          disabled={disabled}
                          siniestroId={siniestroId}
                          onSaveHonorario={onAddIngreso}
                        />
                      )}

                      {!hasRole7 && (
                        <EditObservacionesDialog
                          bienId={bien.id}
                          observaciones={bien.observations}
                          onSave={onSaveObservaciones}
                          onDetailsSaved={handleDetailsUpdated}
                          disabled={disabled}
                        />
                      )}
                      {!hasRole7 && (
                        <EditOtherObservacionesDialog
                          bienId={bien.id}
                          otherObservations={bien.otherObservations}
                          onSave={onOtherObservationsChange}
                          onDetailsSaved={handleDetailsUpdated}
                          disabled={disabled}
                        />
                      )}
                    </AccordionContent>
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>
        )}
        <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Confirmar eliminación</DialogTitle>
              <DialogDescription>
                ¿Estás seguro de que deseas eliminar el bien?
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button onClick={handleDeleteBien}>Confirmar</Button>
              <DialogClose asChild>
                <Button
                  variant="secondary"
                  onClick={() => setShowDeleteDialog(false)}
                >
                  Cancelar
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <AddInformeTecnicoDialog
          bienes={localBienes}
          onSave={(bienId, observaciones) =>
            onSaveObservaciones(bienId, observaciones)
          }
          siniestroId={siniestro.id}
          onSiniestroUpdated={handleSiniestroPartialUpdate}
        />

        {!hasRole7 && (
          <AddBienDialog
            siniestroId={siniestroId}
            onDetailsSaved={handleDetailsUpdated}
            onAddBien={handleAddBien}
            disabled={disabled}
          />
        )}
      </Card>
      {selectedBien && (
        <ImageDialog
          images={
            images.find((img) => img.bienId === selectedBien.id)?.images || []
          }
          isOpen={isOpen}
          onClose={handleCloseDialog}
          refreshImages={refreshImages}
          bienId={selectedBien.id}
          siniestroId={siniestroId}
        />
      )}
    </CardContent>
  );
};

export default BienesSiniestro;
