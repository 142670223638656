import { create } from "zustand";
import { apiFetch } from "@/services/siniestroService";

interface NotificationState {
  unreadCount: number;
  unreadMentionsCount: number;
  setUnreadCount: (count: number) => void;
  setUnreadMentionsCount: (count: number) => void;
  startFetchingUnreadCount: (username: string) => void;
  stopFetchingUnreadCount: () => void;
  resetUnreadCount: () => void;
  fetchInitialUnreadCount: (username: string) => Promise<void>;
}

let intervalId: NodeJS.Timeout | null = null;

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const useNotificationStore = create<NotificationState>((set, get) => ({
  unreadCount: 0,
  unreadMentionsCount: 0,

  setUnreadCount: (count) => set({ unreadCount: count }),
  setUnreadMentionsCount: (count) => set({ unreadMentionsCount: count }),

  fetchInitialUnreadCount: async (username) => {
    if (!username) return;

    // Intentar hasta 3 veces con un retraso entre intentos
    for (let i = 0; i < 3; i++) {
      try {
        // Esperar un poco antes de intentar (aumenta con cada intento)
        await delay(i * 1000);

        const [notificationsResponse, mentionsResponse] = await Promise.all([
          apiFetch(`/api/notificaciones/${username}/unread-count`),
          apiFetch(`/api/notificaciones/${username}/unread-mentions-count`),
        ]);

        const notificationsData = await notificationsResponse.json();
        const mentionsData = await mentionsResponse.json();

        get().setUnreadCount(notificationsData.unreadCount);
        get().setUnreadMentionsCount(mentionsData.unreadCount);

        // Si llegamos aquí, todo salió bien, podemos salir del bucle
        break;
      } catch (error) {
        if (i === 2) {
          // Solo logear el error en el último intento
          console.error(
            "Error fetching initial unread notifications after retries"
          );
        }
        // Si no es el último intento, continuar con el siguiente
        continue;
      }
    }
  },

  startFetchingUnreadCount: (username) => {
    const fetchUnreadCount = async () => {
      try {
        const [notificationsResponse, mentionsResponse] = await Promise.all([
          apiFetch(`/api/notificaciones/${username}/unread-count`),
          apiFetch(`/api/notificaciones/${username}/unread-mentions-count`),
        ]);

        const notificationsData = await notificationsResponse.json();
        const mentionsData = await mentionsResponse.json();

        get().setUnreadCount(notificationsData.unreadCount);
        get().setUnreadMentionsCount(mentionsData.unreadCount);
      } catch (error) {
        // Si hay un error 403, detener el polling
        if ((error as any)?.response?.status === 403) {
          get().stopFetchingUnreadCount();
        }
      }
    };

    // Iniciar el polling
    fetchUnreadCount();
    intervalId = setInterval(fetchUnreadCount, 60000);
  },

  stopFetchingUnreadCount: () => {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
  },

  resetUnreadCount: () => set({ unreadCount: 0, unreadMentionsCount: 0 }),
}));

export default useNotificationStore;
