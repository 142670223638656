/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from "react";
import {
  updateSiniestroDetails,
  fetchProvincias,
  updateProvinceId,
  addAccionToSiniestro,
} from "@/services/siniestroService";
import { toast } from "sonner";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { MdiPencilBoxMultipleOutline } from "@/components/icons/MdiPencilBoxMultipleOutline";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ReloadIcon } from "@radix-ui/react-icons";

interface EditAddressDialogProps {
  siniestro: Siniestro;
  onSiniestroUpdated: (siniestro: Siniestro) => void;
  disabled?: boolean; // Añadir propiedad disabled
}

export const EditAddressDialog = ({
  siniestro,
  onSiniestroUpdated,
  disabled = false, // Valor por defecto
}: EditAddressDialogProps) => {
  const [street, setStreet] = useState(siniestro.occurrenceStreet);
  const [number, setNumber] = useState(siniestro.occurrenceNumber);
  const [floor, setFloor] = useState<string>(
    siniestro.occurrenceFloor?.toString().toUpperCase() || ""
  );
  const [apartment, setApartment] = useState<string>(
    siniestro.occurrenceApartment?.toUpperCase() || ""
  );
  const [zipcode, setZipcode] = useState(siniestro.occurrenceZipcode || ""); // Nuevo estado para CP
  const [locality, setLocality] = useState(siniestro.occurrenceLocality);
  const [province, setProvince] = useState({
    id: siniestro.occurrenceProvinceId,
    name: siniestro.occurrenceProvince,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [provinces, setProvinces] = useState<Provincia[]>([]);

  useEffect(() => {
    const getProvincias = async () => {
      const provincias = await fetchProvincias();
      setProvinces(provincias);
    };

    void getProvincias();
  }, []);

  const handleSave = async () => {
    if (disabled) return; // Evitar que se ejecute si está deshabilitado
    setIsSaving(true);
    try {
      const updatedAddress = {
        occurrenceStreet: street,
        occurrenceNumber: number,
        occurrenceFloor: floor,
        occurrenceApartment: apartment,
        occurrenceZipcode: zipcode,
        occurrenceLocality: locality,
        occurrenceProvince: province.id,
      };

      const updatedSiniestro = {
        ...siniestro,
        ...updatedAddress,
        occurrenceProvince: province.name,
      };
      await updateSiniestroDetails(siniestro.id, updatedSiniestro);

      await updateProvinceId(
        updatedSiniestro.ocurrence_address_id,
        province.id
      );

      // Registrar la acción de edición de dirección
      await addAccionToSiniestro(
        siniestro.id,
        605, // ID de la acción que acabamos de crear
        null, // userAssignedId no es necesario para esta acción
        null // itemId no es necesario para esta acción
      );

      onSiniestroUpdated(updatedSiniestro);
      toast.success("Dirección actualizada");
    } catch (error) {
      // Manejar error
      toast.error("Error al actualizar la dirección");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm" disabled={disabled}>
          <MdiPencilBoxMultipleOutline className="w-4 h-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Editar detalles de la dirección del siniestro
          </DialogTitle>
          <DialogDescription>
            Modifica los detalles de la dirección del siniestro
          </DialogDescription>
        </DialogHeader>
        <Label htmlFor="streetInput">Calle</Label>
        <Input
          id="streetInput"
          value={street}
          onChange={(e) => setStreet(e.target.value)}
          disabled={disabled} // Deshabilitar el campo si es necesario
        />
        <Label htmlFor="numberInput">Número</Label>
        <Input
          id="numberInput"
          type="number"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          disabled={disabled} // Deshabilitar el campo si es necesario
        />
        <Label htmlFor="floorInput">Piso</Label>
        <Input
          id="floorInput"
          type="text"
          value={floor}
          onChange={(e) => setFloor(e.target.value.toUpperCase())}
          disabled={disabled}
        />
        <Label htmlFor="apartmentInput">Departamento</Label>
        <Input
          id="apartmentInput"
          type="text"
          value={apartment}
          onChange={(e) => setApartment(e.target.value.toUpperCase())}
          disabled={disabled}
        />
        <Label htmlFor="localityInput">Localidad</Label>
        <Input
          id="localityInput"
          type="text"
          value={locality}
          onChange={(e) => setLocality(e.target.value)}
          disabled={disabled} // Deshabilitar el campo si es necesario
        />
        <Label htmlFor="zipcodeInput">Código Postal</Label>
        <Input
          id="zipcodeInput"
          type="text"
          value={zipcode}
          onChange={(e) => setZipcode(e.target.value)}
          disabled={disabled} // Deshabilitar el campo si es necesario
        />
        <Label htmlFor="provinceInput">Provincia</Label>

        <Select
          value={province.name}
          onValueChange={(value) => {
            const newSelectedProvince = provinces.find(
              (province) => province.name === value
            );
            if (newSelectedProvince) {
              setProvince({
                id: newSelectedProvince.id,
                name: newSelectedProvince.name,
              });
            }
          }}
          disabled={disabled} // Deshabilitar el select si es necesario
        >
          <SelectTrigger>
            <SelectValue placeholder="Selecciona una provincia" />
          </SelectTrigger>
          <SelectContent className="max-h-[350px] overflow-auto">
            {provinces.map((province) => (
              <SelectItem key={province.id} value={province.name}>
                {province.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <DialogFooter>
          <Button onClick={handleSave} disabled={disabled || isSaving}>
            {isSaving ? (
              <>
                <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                Guardando...
              </>
            ) : (
              "Guardar cambios"
            )}
          </Button>
          <DialogClose asChild>
            <Button variant="secondary" disabled={disabled || isSaving}>
              Cerrar
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
