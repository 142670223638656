import { apiFetch } from "./siniestroService";

export async function fetchGastosProveedores(
  pageIndex: number,
  pageSize: number,
  proveedorId?: number,
  startDate?: Date,
  endDate?: Date,
  isPaid?: boolean
): Promise<any> {
  const params = new URLSearchParams();

  params.append("pageIndex", pageIndex.toString());
  params.append("pageSize", pageSize.toString());

  if (proveedorId !== undefined) {
    params.append("proveedorId", proveedorId.toString());
  }
  if (startDate) {
    params.append("startDate", startDate.toISOString());
  }
  if (endDate) {
    params.append("endDate", endDate.toISOString());
  }
  if (isPaid !== undefined) {
    params.append("isPaid", isPaid !== undefined ? (isPaid ? "1" : "0") : "");
  }

  const response = await apiFetch(
    `/api/gastos-siniestros/proveedores?${params.toString()}`
  );

  if (!response.ok) {
    throw new Error("Error al obtener los gastos de proveedores");
  }

  const data = await response.json();

  return data;
}

export async function approveGasto(gastoId: number): Promise<any> {
  const response = await apiFetch(`/api/gastos-siniestros/${gastoId}/aprobar`, {
    method: "POST",
  });

  if (!response.ok) {
    throw new Error("Error al aprobar el gasto");
  }

  return await response.json();
}

export async function exportGastosProveedoresExcel(
  proveedorId?: number,
  startDate?: Date,
  endDate?: Date
) {
  const params = new URLSearchParams();

  if (proveedorId !== undefined) {
    params.append("proveedorId", proveedorId.toString());
  }
  if (startDate) {
    params.append("startDate", startDate.toISOString());
  }
  if (endDate) {
    params.append("endDate", endDate.toISOString());
  }

  const response = await apiFetch(
    `/api/gastos-siniestros/export?${params.toString()}`
  );

  if (!response.ok) {
    throw new Error("Error al exportar los datos");
  }

  return response.blob();
}

export const updateGastoStatus = async (id: number, isPaid: boolean) => {
  const response = await apiFetch(
    `/api/gastos-siniestros/proveedores/${id}/status`,
    {
      method: "PATCH",
      body: JSON.stringify({ isPaid }),
    }
  );

  if (!response.ok) {
    throw new Error("Error al actualizar el estado del gasto");
  }

  return await response.json();
};
