import React from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface DateFilterProps {
  selectedStartYear: number;
  selectedStartMonth: number;
  selectedEndYear: number;
  selectedEndMonth: number;
  onStartYearChange: (value: string) => void;
  onStartMonthChange: (value: string) => void;
  onEndYearChange: (value: string) => void;
  onEndMonthChange: (value: string) => void;
  onPeriodChange: (value: string) => void;
}

const DateFilter: React.FC<DateFilterProps> = ({
  selectedStartYear,
  selectedStartMonth,
  selectedEndYear,
  selectedEndMonth,
  onStartYearChange,
  onStartMonthChange,
  onEndYearChange,
  onEndMonthChange,
  onPeriodChange,
}) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
  const months = [
    { value: 1, label: "Enero" },
    { value: 2, label: "Febrero" },
    { value: 3, label: "Marzo" },
    { value: 4, label: "Abril" },
    { value: 5, label: "Mayo" },
    { value: 6, label: "Junio" },
    { value: 7, label: "Julio" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Septiembre" },
    { value: 10, label: "Octubre" },
    { value: 11, label: "Noviembre" },
    { value: 12, label: "Diciembre" },
  ];

  const periods = [
    { value: "custom", label: "Personalizado" },
    { value: "currentMonth", label: "Mes Actual" },
    { value: "lastMonth", label: "Mes Anterior" },
    { value: "currentQuarter", label: "Trimestre Actual" },
    { value: "lastQuarter", label: "Trimestre Anterior" },
    { value: "currentYear", label: "Año Actual" },
    { value: "lastYear", label: "Año Anterior" },
  ];

  return (
    <div className="flex flex-col space-y-2">
      <Select defaultValue="custom" onValueChange={onPeriodChange}>
        <SelectTrigger className="w-[200px]">
          <SelectValue placeholder="Seleccionar período" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {periods.map((period) => (
              <SelectItem key={period.value} value={period.value}>
                {period.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>

      <div className="flex space-x-4">
        <div className="flex space-x-2">
          <Select
            value={selectedStartYear.toString()}
            onValueChange={onStartYearChange}
          >
            <SelectTrigger className="w-[100px]">
              <SelectValue placeholder="Año inicial" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {years.map((year) => (
                  <SelectItem key={year} value={year.toString()}>
                    {year}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          <Select
            value={selectedStartMonth.toString()}
            onValueChange={onStartMonthChange}
          >
            <SelectTrigger className="w-[100px]">
              <SelectValue placeholder="Mes inicial" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {months.map((month) => (
                  <SelectItem
                    key={month.value}
                    value={month.value.toString()}
                    disabled={
                      selectedStartYear === currentYear &&
                      month.value > currentMonth
                    }
                  >
                    {month.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="flex items-center">hasta</div>

        <div className="flex space-x-2">
          <Select
            value={selectedEndYear.toString()}
            onValueChange={onEndYearChange}
          >
            <SelectTrigger className="w-[100px]">
              <SelectValue placeholder="Año final" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {years.map((year) => (
                  <SelectItem key={year} value={year.toString()}>
                    {year}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          <Select
            value={selectedEndMonth.toString()}
            onValueChange={onEndMonthChange}
          >
            <SelectTrigger className="w-[100px]">
              <SelectValue placeholder="Mes final" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {months.map((month) => (
                  <SelectItem
                    key={month.value}
                    value={month.value.toString()}
                    disabled={
                      selectedEndYear === currentYear &&
                      month.value > currentMonth
                    }
                  >
                    {month.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
