import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FaFilter } from "react-icons/fa";
import DateFilter from "./DateFilter";

interface MainFilterProps {
  companies: Array<{ id: number; name: string }>;
  itemTypes: Array<{ id: number; name: string }>;
  filters: {
    companyId?: string;
    itemTypeName: string;
  };
  selectedStartYear: number;
  selectedStartMonth: number;
  selectedEndYear: number;
  selectedEndMonth: number;
  onFilterChange: (field: string, value: any) => void;
  onStartYearChange: (year: string) => void;
  onStartMonthChange: (month: string) => void;
  onEndYearChange: (year: string) => void;
  onEndMonthChange: (month: string) => void;
  onPeriodChange: (period: string) => void;
  onApplyFilters: () => void;
  hasRole7: boolean;
}

export function MainFilter({
  companies,
  itemTypes,
  filters,
  selectedStartYear,
  selectedStartMonth,
  selectedEndYear,
  selectedEndMonth,
  onFilterChange,
  onStartYearChange,
  onStartMonthChange,
  onEndYearChange,
  onEndMonthChange,
  onPeriodChange,
  onApplyFilters,
  hasRole7,
}: MainFilterProps) {
  return (
    <Card className="mb-6">
      <CardHeader className="pb-3">
        <CardTitle className="flex items-center gap-2 text-lg font-medium">
          <FaFilter className="h-4 w-4 text-muted-foreground" />
          Filtros principales
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col md:flex-row items-end gap-6">
          <div className="flex flex-wrap items-end gap-6">
            {/* Compañía - Solo visible para no-role7 */}
            {!hasRole7 ? (
              <div className="flex flex-col gap-2">
                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                  Compañía
                </label>
                <Select
                  onValueChange={(value) => onFilterChange("companyId", value)}
                  value={filters.companyId?.toString() || undefined}
                >
                  <SelectTrigger className="h-10 w-[180px]">
                    <SelectValue placeholder="Seleccionar" />
                  </SelectTrigger>
                  <SelectContent className="overflow-y-auto max-h-[20rem]">
                    {companies.map((company) => (
                      <SelectItem
                        key={company.id}
                        value={company.id.toString()}
                      >
                        {company.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            ) : null}

            {/* Segmento */}
            <div className="flex flex-col gap-2">
              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                Segmento
              </label>
              <Select
                onValueChange={(value) => onFilterChange("itemTypeName", value)}
                value={filters.itemTypeName || undefined}
                required
              >
                <SelectTrigger className="h-10 w-[180px]">
                  <SelectValue placeholder="Seleccionar" />
                </SelectTrigger>
                <SelectContent className="overflow-y-auto max-h-[20rem]">
                  {itemTypes.map((type) => (
                    <SelectItem key={type.id} value={type.name}>
                      {type.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            {/* Período */}
            <div className="flex flex-col gap-2">
              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                Período
              </label>
              <DateFilter
                selectedStartYear={selectedStartYear}
                selectedStartMonth={selectedStartMonth}
                selectedEndYear={selectedEndYear}
                selectedEndMonth={selectedEndMonth}
                onStartYearChange={onStartYearChange}
                onStartMonthChange={onStartMonthChange}
                onEndYearChange={onEndYearChange}
                onEndMonthChange={onEndMonthChange}
                onPeriodChange={onPeriodChange}
              />
            </div>
          </div>

          {/* Botón Aplicar Filtros */}
          <Button
            onClick={onApplyFilters}
            className="md:ml-auto w-full md:w-auto min-w-[150px]"
            disabled={!filters.itemTypeName}
            size="lg"
          >
            Aplicar filtros
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
