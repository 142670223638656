import { create } from "zustand";
import { apiFetch } from "@/services/siniestroService";

interface NotificationState {
  unreadCount: number;
  unreadMentionsCount: number;
  setUnreadCount: (count: number) => void;
  setUnreadMentionsCount: (count: number) => void;
  startFetchingUnreadCount: (
    username: string,
    isAuthenticated: boolean
  ) => void;
  stopFetchingUnreadCount: () => void;
  resetUnreadCount: () => void;
  fetchInitialUnreadCount: (
    username: string,
    isAuthenticated: boolean
  ) => Promise<void>;
}

let intervalId: NodeJS.Timeout | null = null;

const useNotificationStore = create<NotificationState>((set, get) => ({
  unreadCount: 0,
  unreadMentionsCount: 0,

  setUnreadCount: (count) => set({ unreadCount: count }),
  setUnreadMentionsCount: (count) => set({ unreadMentionsCount: count }),

  fetchInitialUnreadCount: async (username, isAuthenticated) => {
    if (!username || !isAuthenticated) {
      get().resetUnreadCount();
      return;
    }

    try {
      const [notificationsResponse, mentionsResponse] = await Promise.all([
        apiFetch(`/api/notificaciones/${username}/unread-count`),
        apiFetch(`/api/notificaciones/${username}/unread-mentions-count`),
      ]);

      if (!notificationsResponse.ok || !mentionsResponse.ok) {
        throw new Error("Error en la respuesta del servidor");
      }

      const notificationsData = await notificationsResponse.json();
      const mentionsData = await mentionsResponse.json();

      get().setUnreadCount(notificationsData.unreadCount);
      get().setUnreadMentionsCount(mentionsData.unreadCount);
    } catch (error) {
      console.warn("Error fetching initial counts:", error);
      get().resetUnreadCount();
    }
  },

  startFetchingUnreadCount: (username, isAuthenticated) => {
    if (!username || !isAuthenticated) {
      get().stopFetchingUnreadCount();
      get().resetUnreadCount();
      return;
    }

    get().stopFetchingUnreadCount();

    const fetchUnreadCount = async () => {
      try {
        const [notificationsResponse, mentionsResponse] = await Promise.all([
          apiFetch(`/api/notificaciones/${username}/unread-count`),
          apiFetch(`/api/notificaciones/${username}/unread-mentions-count`),
        ]);

        // Si cualquiera de las respuestas es 401, detener el polling
        if (
          notificationsResponse.status === 401 ||
          mentionsResponse.status === 401
        ) {
          get().stopFetchingUnreadCount();
          get().resetUnreadCount();
          return;
        }

        if (!notificationsResponse.ok || !mentionsResponse.ok) {
          throw new Error("Error en la respuesta del servidor");
        }

        const notificationsData = await notificationsResponse.json();
        const mentionsData = await mentionsResponse.json();

        get().setUnreadCount(notificationsData.unreadCount);
        get().setUnreadMentionsCount(mentionsData.unreadCount);
      } catch (error) {
        get().stopFetchingUnreadCount();
        get().resetUnreadCount();
      }
    };

    fetchUnreadCount();
    intervalId = setInterval(fetchUnreadCount, 60000);
  },

  stopFetchingUnreadCount: () => {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
  },

  resetUnreadCount: () => set({ unreadCount: 0, unreadMentionsCount: 0 }),
}));

export default useNotificationStore;
