import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { HiClock, HiTrendingUp, HiTrendingDown } from "react-icons/hi";

interface StatsCardProps {
  title: string;
  value: number | null;
  unit?: string;
  trend?: number;
  className?: string;
  decimals?: number;
}

export function StatsCard({
  title,
  value = 0,
  unit,
  trend,
  className,
}: StatsCardProps) {
  return (
    <Card className={cn("", className)}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{title}</CardTitle>
        <HiClock className="h-4 w-4 text-muted-foreground" />
      </CardHeader>
      <CardContent>
        <div className="flex items-center justify-between">
          <div>
            {value !== undefined && (
              <p className="text-2xl font-bold">
                {typeof value === "number"
                  ? `${value.toFixed(value % 1 === 0 ? 0 : 2)} ${unit}`
                  : `${value} ${unit}`}
              </p>
            )}
            {trend !== undefined && (
              <p
                className={cn(
                  "text-xs",
                  trend > 0 ? "text-green-600" : "text-red-600"
                )}
              >
                <span className="flex items-center gap-1">
                  {trend > 0 ? <HiTrendingUp /> : <HiTrendingDown />}
                  {Math.abs(trend)}% vs. período anterior
                </span>
              </p>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
