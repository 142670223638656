import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useEffect, useState } from "react";
import { fetchEstados } from "@/services/estadoService";

interface EstadoFilterProps {
  onChange: (value: string | undefined) => void;
  selectedValue?: string;
}

export const EstadoFilter: React.FC<EstadoFilterProps> = ({
  selectedValue,
  onChange,
}) => {
  const [estados, setEstados] = useState<{ id: string; name: string }[]>([]);
  const [selectedEstado, setSelectedEstado] = useState<string | undefined>(
    selectedValue || "all" // Valor predeterminado inicial
  );

  useEffect(() => {
    const fetchAndSetEstados = async () => {
      try {
        const fetchedEstados = await fetchEstados();
        // Ordenar estados alfabéticamente por nombre
        const sortedEstados = fetchedEstados.sort((a: any, b: any) =>
          a.name.localeCompare(b.name)
        );
        setEstados(sortedEstados);
      } catch (error) {}
    };

    fetchAndSetEstados();
  }, []);

  // Actualizar selectedEstado cuando selectedValue cambie
  useEffect(() => {
    setSelectedEstado(selectedValue || "all");
  }, [selectedValue]);

  return (
    <Select
      value={selectedEstado}
      onValueChange={(value) => {
        const newSelectedValue =
          value === "no_cerrado"
            ? "no_cerrado"
            : value === "all"
              ? undefined
              : value;
        setSelectedEstado(newSelectedValue);
        onChange(newSelectedValue);
      }}
    >
      <SelectTrigger className="w-[170px]">
        <SelectValue>
          {selectedEstado === "no_cerrado"
            ? "Abiertos"
            : selectedEstado === "all"
              ? "Todos (estados)"
              : estados.find((estado) => estado.id === selectedEstado)?.name ||
                "Estado"}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup className="overflow-y-auto max-h-[25rem]">
          <SelectLabel>Estados</SelectLabel>
          <SelectItem key="all" value="all">
            Todos
          </SelectItem>
          <SelectItem key="no_cerrado" value="no_cerrado">
            Abiertos
          </SelectItem>
          {estados.map((estado) => (
            <SelectItem key={estado.id} value={estado.id}>
              {estado.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
