import { apiFetch } from "@/services/siniestroService";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface AuthContextType {
  username: string | null;
  roles: number[];
  isAuthenticated: boolean;
  isDisabled: boolean;
  isAuthChecked: boolean;
  login: (
    username: string,
    isDisabled: boolean,
    roles: number[],
    sessionId: number
  ) => void;
  logout: () => Promise<void>;
  checkAuth: () => Promise<void>;
}

// Proporcionar valores por defecto al contexto
const AuthContext = createContext<AuthContextType>({
  username: null,
  roles: [],
  isAuthenticated: false,
  isDisabled: false,
  isAuthChecked: false,
  login: () => {},
  logout: async () => {},
  checkAuth: async () => {},
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [username, setUsername] = useState<string | null>(null);
  const [roles, setRoles] = useState<number[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false);
  const [isInitializing, setIsInitializing] = useState<boolean>(true);

  const login = (
    username: string,
    isDisabled: boolean,
    roles: number[],
    sessionId: number
  ) => {
    localStorage.setItem("sessionId", sessionId.toString());
    setUsername(username);
    setRoles(roles);
    setIsAuthenticated(!isDisabled);
    setIsDisabled(isDisabled);
  };

  // Función para guardar logs
  const saveLog = (message: string, data?: any) => {
    const timestamp = new Date().toISOString();
    const log = { timestamp, message, data };
    const logs = JSON.parse(localStorage.getItem("auth_logs") || "[]");
    logs.push(log);
    localStorage.setItem("auth_logs", JSON.stringify(logs));
  };

  const logout = async () => {
    saveLog("Iniciando proceso de logout...");
    try {
      const sessionId = localStorage.getItem("sessionId");
      await apiFetch("/api/users/logout", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          sessionId: sessionId ? parseInt(sessionId) : null,
        }),
      });
      saveLog("Logout exitoso en el servidor");
    } catch (error) {
      saveLog("Error durante logout:", error);
    } finally {
      saveLog("Limpiando estado local...");
      localStorage.removeItem("userId");
      localStorage.removeItem("username");
      localStorage.removeItem("state");
      localStorage.removeItem("sessionId");
      setUsername(null);
      setRoles([]);
      setIsAuthenticated(false);
      setIsDisabled(false);
      saveLog("Estado local limpiado");
    }
  };

  const checkAuth = async () => {
    try {
      saveLog("Iniciando checkAuth...");
      let response = await apiFetch("/api/users/check-auth", {
        method: "GET",
      });

      saveLog("Respuesta de check-auth:", {
        status: response.status,
        statusText: response.statusText,
      });

      const handleAuthResponse = async (response: Response) => {
        if (response.ok) {
          const data = await response.json();
          saveLog("Respuesta exitosa de auth:", data);
          setUsername(data.username);
          setRoles(data.roles || []);
          setIsAuthenticated(true);
          setIsDisabled(!data.state);
          return true;
        }
        return false;
      };

      if (await handleAuthResponse(response)) {
        return;
      }

      // Si la respuesta no es ok, intentamos leer el mensaje de error
      try {
        const errorData = await response.json();
        saveLog("Error data recibida:", {
          status: response.status,
          message: errorData.message,
          fullError: errorData,
        });

        // Solo intentar renovar el token si es un error de token
        if (
          response.status === 401 &&
          (errorData.message === "TOKEN_EXPIRED" ||
            errorData.message === "TOKEN_INACTIVE")
        ) {
          saveLog("Intentando renovar token...");
          const refreshResponse = await apiFetch("/api/users/refresh-token", {
            method: "POST",
          });

          saveLog("Respuesta de refresh-token:", {
            status: refreshResponse.status,
            statusText: refreshResponse.statusText,
          });

          if (refreshResponse.ok) {
            response = await apiFetch("/api/users/check-auth", {
              method: "GET",
            });

            if (await handleAuthResponse(response)) {
              return;
            }
          }

          saveLog("No se pudo renovar el token, haciendo logout");
          await logout();
          return;
        }

        // Para cualquier otro tipo de error (incluyendo 403), mantener la sesión
        saveLog(`Error ${response.status} recibido, manteniendo sesión`);
      } catch (parseError) {
        saveLog("Error parseando respuesta:", parseError);
      }
    } catch (error) {
      saveLog("Error en checkAuth:", error);
      // No hacer logout por errores de red u otros errores
    } finally {
      setIsAuthChecked(true);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      await checkAuth();
      setIsInitializing(false);
    };
    initialize();
  }, []);

  if (isInitializing) {
    return null; // o un componente de carga
  }

  return (
    <AuthContext.Provider
      value={{
        username,
        roles,
        isAuthenticated,
        isDisabled,
        isAuthChecked,
        login,
        logout,
        checkAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
