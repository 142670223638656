// authStore.ts
import { create } from "zustand";
const BASE_URL =
  import.meta.env.MODE === "production"
    ? import.meta.env.VITE_API_URL_PROD
    : import.meta.env.VITE_API_URL_DEV;

interface AuthState {
  isAuthenticated: boolean;
  userId: number | null;
  username: string | null;
  userDisabled: boolean;
  roles: number[];
  sessionId: number | null;
  login: (
    username: string,
    isDisabled: boolean,
    sessionId: number | null
  ) => void;
  logout: () => Promise<void>;
  setUserDisabled: (disabled: boolean) => void;
  getRoles: () => Promise<void>;
  errorMessage: string | null;
  setErrorMessage: (message: string | null) => void;
}

const useAuthStore = create<AuthState>((set, get) => ({
  isAuthenticated: Boolean(localStorage.getItem("username")),
  userId: Number(localStorage.getItem("userId")),
  username: localStorage.getItem("username"),
  userDisabled: localStorage.getItem("state") === "disabled",
  roles: JSON.parse(localStorage.getItem("roles") || "[]"),
  sessionId: null,
  login: (username: string, isDisabled: boolean, sessionId: number | null) => {
    localStorage.setItem("username", username);
    localStorage.setItem("state", isDisabled ? "disabled" : "enabled");

    set({
      isAuthenticated: true,
      username,
      userDisabled: isDisabled,
      sessionId: sessionId || null, // Asegurarnos de que nunca sea undefined
    });
  },
  logout: async () => {
    try {
      const currentSessionId = get().sessionId;

      if (typeof currentSessionId === "number") {
        // Verificar que sea un número
        await fetch(`${BASE_URL}/api/users/logout`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ sessionId: currentSessionId }),
        });
      }

      localStorage.removeItem("username");
      localStorage.removeItem("state");
      localStorage.removeItem("roles");

      set({
        isAuthenticated: false,
        userId: null,
        username: null,
        userDisabled: false,
        roles: [],
        sessionId: null,
      });
    } catch (error) {
      console.error("Error durante logout:", error);
    }
  },
  setUserDisabled: (disabled: boolean) => {
    set({ userDisabled: disabled });
  },
  getRoles: async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/users/roles`, {
        credentials: "include",
      });
      const data = await response.json();
      set({ roles: data.roles });
      localStorage.setItem("roles", JSON.stringify(data.roles)); // Guardar roles en localStorage
    } catch (error) {}
  },
  errorMessage: null,
  setErrorMessage: (message) => {
    set({ errorMessage: message });
  },
}));

export default useAuthStore;
