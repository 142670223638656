import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { apiFetch } from "@/services/siniestroService";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface LaCajaOrderConformityDialogProps {
  siniestro: {
    companyIncidentNumber: string;
  };
  onClose: () => void;
}

const LaCajaOrderConformityDialog: React.FC<
  LaCajaOrderConformityDialogProps
> = ({ siniestro, onClose }) => {
  const [monto, setMonto] = useState<string>("");
  const [fecha, setFecha] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [comercio, setComercio] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const handleExportPdf = async () => {
    setIsLoading(true);
    try {
      const response = await apiFetch("/api/pdf/lacaja-order-conformity", {
        method: "POST",
        body: JSON.stringify({
          siniestroNumber: siniestro.companyIncidentNumber,
          monto: parseFloat(monto),
          fecha: fecha,
          comercio: comercio,
        }),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Conformidad_LaCaja_OC_${siniestro.companyIncidentNumber}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error("Failed to generate PDF");
      }
    } catch (error) {
      console.error("Error exporting PDF:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Generar conformidad La Caja - Orden de Compra
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div>
            <Label>Monto de la conformidad:</Label>
            <Input
              type="number"
              min="0"
              step="0.01"
              value={monto}
              onChange={(e) => {
                const value = e.target.value;
                if (
                  value === "" ||
                  (/^\d*\.?\d*$/.test(value) && parseFloat(value) >= 0)
                ) {
                  setMonto(value);
                }
              }}
              placeholder="Ingrese el monto en pesos"
            />
          </div>
          <div>
            <Label>Fecha:</Label>
            <Input
              type="date"
              value={fecha}
              onChange={(e) => setFecha(e.target.value)}
            />
          </div>
          <div>
            <Label>Comercio:</Label>
            <Select value={comercio} onValueChange={setComercio}>
              <SelectTrigger>
                <SelectValue placeholder="Seleccione el comercio" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Frávega">Frávega</SelectItem>
                <SelectItem value="Musimundo">Musimundo</SelectItem>
                <SelectItem value="Megatone">Megatone</SelectItem>
                <SelectItem value="On City">On City</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <DialogFooter>
          <Button
            onClick={handleExportPdf}
            disabled={
              isLoading ||
              !monto ||
              parseFloat(monto) <= 0 ||
              !fecha ||
              !comercio
            }
          >
            {isLoading ? (
              <>
                <AiOutlineLoading3Quarters className="mr-2 h-4 w-4 animate-spin" />
                Exportando...
              </>
            ) : (
              "Exportar PDF"
            )}
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cerrar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LaCajaOrderConformityDialog;
