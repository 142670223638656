import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Line,
  LineChart,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { motion, AnimatePresence } from "framer-motion";
import { Skeleton } from "@/components/ui/skeleton";

interface Props {
  data: any[];
  isLoading: boolean;
  selectedYear: number;
  onYearChange: (year: string) => void;
}

export const PromedioLineChart = ({
  data,
  isLoading,
  selectedYear,
  onYearChange,
}: Props) => {
  // Generar array de años desde 2020 hasta el año actual
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 2019 },
    (_, i) => currentYear - i
  );

  return (
    <Card>
      <CardHeader className="space-y-4">
        <CardTitle>Promedio de cierre mensual por año</CardTitle>
        <Select value={selectedYear.toString()} onValueChange={onYearChange}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Seleccionar año" />
          </SelectTrigger>
          <SelectContent>
            {years.map((year) => (
              <SelectItem key={year} value={year.toString()}>
                {year}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </CardHeader>
      <CardContent>
        <div className="h-[400px] relative">
          <AnimatePresence mode="wait">
            {isLoading ? (
              <motion.div
                key="loading"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 flex items-center justify-center"
              >
                <div className="space-y-4 w-full">
                  <Skeleton className="h-[400px] w-full" />
                  <motion.div
                    className="w-full h-1 bg-primary/10 rounded-full overflow-hidden"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                  >
                    <motion.div
                      className="h-full bg-primary"
                      initial={{ x: "-100%" }}
                      animate={{ x: "100%" }}
                      transition={{
                        repeat: Infinity,
                        duration: 1.5,
                        ease: "linear",
                      }}
                    />
                  </motion.div>
                </div>
              </motion.div>
            ) : (
              <motion.div
                key="chart"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="h-full"
              >
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={data}
                    margin={{
                      top: 40,
                      right: 30,
                      left: 35,
                      bottom: 10,
                    }}
                  >
                    <XAxis
                      dataKey="month"
                      tickLine={false}
                      axisLine={false}
                      tickMargin={8}
                      tickFormatter={(value) => {
                        const monthIndex = parseInt(value.split("-")[1]) - 1;
                        const monthNames = [
                          "Enero",
                          "Febrero",
                          "Marzo",
                          "Abril",
                          "Mayo",
                          "Junio",
                          "Julio",
                          "Agosto",
                          "Septiembre",
                          "Octubre",
                          "Noviembre",
                          "Diciembre",
                        ];
                        return monthNames[monthIndex];
                      }}
                    />
                    <Tooltip
                      formatter={(value: number) => [
                        `${value.toFixed(1)} días`,
                        "Promedio",
                      ]}
                      labelFormatter={(label) => {
                        const monthIndex = parseInt(label.split("-")[1]) - 1;
                        const monthNames = [
                          "Enero",
                          "Febrero",
                          "Marzo",
                          "Abril",
                          "Mayo",
                          "Junio",
                          "Julio",
                          "Agosto",
                          "Septiembre",
                          "Octubre",
                          "Noviembre",
                          "Diciembre",
                        ];
                        return `${monthNames[monthIndex]} ${selectedYear}`;
                      }}
                    />
                    <Line
                      type="monotone"
                      dataKey="promedio"
                      stroke="#8884d8"
                      strokeWidth={2}
                      dot={{
                        fill: "#8884d8",
                        r: 4,
                      }}
                      activeDot={{
                        r: 6,
                      }}
                    >
                      <LabelList
                        dataKey="promedio"
                        position="top"
                        formatter={(value: number) => value.toFixed(1)}
                        offset={10}
                      />
                    </Line>
                    <text
                      x="50%"
                      y={20}
                      textAnchor="middle"
                      style={{
                        fontSize: "24px",
                        fill: "#333",
                        fontWeight: "600",
                        fontFamily: "system-ui, -apple-system, sans-serif",
                      }}
                    >
                      {selectedYear}
                    </text>
                  </LineChart>
                </ResponsiveContainer>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </CardContent>
    </Card>
  );
};
