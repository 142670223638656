import React, { useEffect, useState } from "react";
import { getActiveSessions } from "../../services/userService";
import { motion } from "framer-motion";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  FaDesktop,
  FaClock,
  FaUser,
  FaNetworkWired,
  FaSync,
} from "react-icons/fa";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface Session {
  id: number;
  user_id: number;
  username: string;
  login_time: string;
  last_activity: string;
  ip_address: string;
  device_info: string;
  duration_seconds: number;
}

const LoadingDots = () => (
  <div className="flex justify-center items-center h-64">
    <div className="space-x-2 flex items-center">
      {[0, 1, 2].map((index) => (
        <motion.div
          key={index}
          className="w-4 h-4 bg-blue-500 rounded-full"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.3, 1, 0.3],
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
            delay: index * 0.2,
          }}
        />
      ))}
    </div>
    <motion.p
      className="ml-4 text-lg text-gray-600"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      Cargando sesiones...
    </motion.p>
  </div>
);

export const ActiveSessions: React.FC = () => {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const formatDateTime = (dateString: string): string => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("es-AR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "UTC",
    }).format(date);
  };

  const formatInactiveTime = (lastActivity: string): string => {
    const lastActivityDate = new Date(lastActivity);
    const now = new Date();
    const diffSeconds = Math.floor(
      (now.getTime() - lastActivityDate.getTime() - 3 * 60 * 60 * 1000) / 1000
    );

    if (diffSeconds < 60) return "Activo";
    const minutes = Math.floor(diffSeconds / 60);
    if (minutes < 60) return `Inactivo ${minutes}m`;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `Inactivo ${hours}h ${remainingMinutes}m`;
  };

  const fetchActiveSessions = async () => {
    setLoading(true);
    try {
      const data = await getActiveSessions();
      setSessions(data);
      setError(null);
    } catch (err) {
      setSessions([]);
      setError(err instanceof Error ? err.message : "Error al cargar sesiones");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActiveSessions();
  }, []);

  if (loading) return <LoadingDots />;
  if (error)
    return <div className="text-red-500 text-center p-4">Error: {error}</div>;

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Sesiones activas</h2>
        <button
          onClick={fetchActiveSessions}
          disabled={loading}
          className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {loading ? (
            <span>Actualizando...</span>
          ) : (
            <>
              <FaSync className={loading ? "animate-spin" : ""} />
              <span>Actualizar</span>
            </>
          )}
        </button>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>
                <div className="flex items-center space-x-2">
                  <FaUser className="text-muted-foreground" />
                  <span>Usuario</span>
                </div>
              </TableHead>
              <TableHead>
                <div className="flex items-center space-x-2">
                  <FaClock className="text-muted-foreground" />
                  <span>Primer inicio de sesión en el día</span>
                </div>
              </TableHead>
              <TableHead>
                <div className="flex items-center space-x-2">
                  <FaClock className="text-muted-foreground" />
                  <span>Última actividad</span>
                </div>
              </TableHead>
              <TableHead>
                <div className="flex items-center space-x-2">
                  <FaNetworkWired className="text-muted-foreground" />
                  <span>IP</span>
                </div>
              </TableHead>
              <TableHead>
                <div className="flex items-center space-x-2">
                  <FaDesktop className="text-muted-foreground" />
                  <span>Dispositivo</span>
                </div>
              </TableHead>
              <TableHead>
                <div className="flex items-center space-x-2">
                  <FaClock className="text-muted-foreground" />
                  <span>Inactividad</span>
                </div>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sessions.map((session) => (
              <TableRow key={session.id}>
                <TableCell className="font-medium">
                  {session.username}
                </TableCell>
                <TableCell>{formatDateTime(session.login_time)}</TableCell>
                <TableCell>{formatDateTime(session.last_activity)}</TableCell>
                <TableCell>{session.ip_address}</TableCell>
                <TableCell>
                  <TooltipProvider delayDuration={50}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="max-w-[300px] truncate cursor-default">
                          {session.device_info}
                        </div>
                      </TooltipTrigger>
                      <TooltipContent className="max-w-[400px] p-2">
                        {session.device_info.split(",").map((info, i) => (
                          <div key={i} className="py-0.5">
                            {info.trim()}
                          </div>
                        ))}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </TableCell>
                <TableCell className="font-mono">
                  {formatInactiveTime(session.last_activity)}
                </TableCell>
              </TableRow>
            ))}
            {sessions.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={6}
                  className="text-center py-8 text-gray-500"
                >
                  No hay sesiones activas
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
