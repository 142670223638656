import { useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
  type ColumnFiltersState,
  type VisibilityState,
  type SortingState,
} from "@tanstack/react-table";
import {
  Table as UITable,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { GastosProveedoresColumns } from "./GastosProveedoresColumns";
import { GastosProveedoresDataTablePagination } from "./GastosProveedoresDataTablePagination";
import Spinner from "../ui/spinner";
import {
  fetchGastosProveedores,
  updateGastoStatus,
  exportGastosProveedoresExcel,
} from "@/services/gastosService";
import { Button } from "../ui/button";
import { toast } from "sonner";
import { parseISO } from "date-fns";
import { ProveedorFilter } from "./filters/ProveedorFilter";
import { DateFilter } from "./filters/DateFilter";
import { ColumnDef } from "@tanstack/react-table";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import MotionNumber from "motion-number";
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card";
import { startOfDay, endOfDay, format } from "date-fns";
import { es } from "date-fns/locale";
import { addAccionToSiniestro } from "@/services/siniestroService";

const formatDateRange = (start: Date, end: Date) => {
  if (start.toDateString() === end.toDateString()) {
    return format(start, "d 'de' MMMM, yyyy", { locale: es });
  }
  if (
    start.getMonth() === end.getMonth() &&
    start.getFullYear() === end.getFullYear()
  ) {
    return `${format(start, "d", { locale: es })} al ${format(
      end,
      "d 'de' MMMM, yyyy",
      { locale: es }
    )}`;
  }
  return `${format(start, "d 'de' MMMM", { locale: es })} al ${format(
    end,
    "d 'de' MMMM, yyyy",
    { locale: es }
  )}`;
};

const formatDate = (dateString: string | null | undefined): string => {
  if (!dateString || isNaN(Date.parse(dateString))) {
    return "Fecha no disponible";
  }
  const date = parseISO(dateString);
  return `${String(date.getUTCDate()).padStart(2, "0")}/${String(
    date.getUTCMonth() + 1
  ).padStart(2, "0")}/${date.getUTCFullYear()}`;
};

interface DataTableProps<TData extends WithId, TValue> {
  columns: Array<ColumnDef<TData, TValue>>;
  currentPage: number;
}

export function GastosProveedoresDataTable({
  currentPage,
}: DataTableProps<GastoProveedor, unknown>) {
  const [gastos, setGastos] = useState<GastoProveedor[]>([]);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [selectedProveedor, setSelectedProveedor] = useState<
    number | undefined
  >(undefined);
  const [startDate, setStartDate] = useState<Date>(startOfDay(new Date()));
  const [endDate, setEndDate] = useState<Date>(endOfDay(new Date()));
  const [statusFilter, setStatusFilter] = useState<boolean | undefined>(
    undefined
  );
  const [pagination, setPagination] = useState({
    pageIndex: currentPage,
    pageSize: 10,
  });

  const cargarGastos = async () => {
    setIsLoading(true);
    try {
      const response = await fetchGastosProveedores(
        pagination.pageIndex,
        pagination.pageSize,
        selectedProveedor,
        startDate,
        endDate,
        statusFilter
      );

      setGastos(response.gastosProveedores.recordset);
      setTotalValue(response.gastosProveedores.totalValue);
      setPageCount(
        Math.ceil(response.gastosProveedores.totalCount / pagination.pageSize)
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    cargarGastos();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    selectedProveedor,
    startDate,
    endDate,
    statusFilter,
  ]);

  useEffect(() => {
    // Cuando se cambie el proveedor o las fechas, reinicia la paginación a la página 1
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }));
  }, [selectedProveedor, startDate, endDate]);

  const updateGasto = (gastoId: number, newStatus: boolean) => {
    setGastos((gastosPrev) =>
      gastosPrev.map((gasto) =>
        gasto.gasto_proveedor_id === gastoId
          ? { ...gasto, is_paid: newStatus }
          : gasto
      )
    );
  };

  const columns = GastosProveedoresColumns(updateGasto);

  const table = useReactTable({
    data: gastos,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: (newPagination) => {
      setPagination(newPagination);
    },
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      pagination,
    },
    manualPagination: true,
    pageCount,
  });

  const handleUpdateStatusClick = async (newStatus: boolean) => {
    const selectedRows = table.getSelectedRowModel().rows;
    try {
      for (const row of selectedRows) {
        const gastoId = row.original.gasto_proveedor_id;
        const siniestroId = row.original.incident_id;
        const idGasto = row.original.id_gasto;

        // Actualizar el estado del gasto
        await updateGastoStatus(gastoId, newStatus);

        // Registrar la acción
        const idActionType = newStatus ? 59 : 60; // 59: pagado, 60: pendiente
        await addAccionToSiniestro(
          siniestroId,
          idActionType,
          null, // userAssignedId
          idGasto // itemId
        );

        // Actualizar el estado local
        updateGasto(gastoId, newStatus);
      }

      toast.success(
        newStatus
          ? "Gastos marcados como pagados correctamente"
          : "Gastos marcados como pendientes correctamente"
      );

      // Recargar los datos para asegurar sincronización
      await cargarGastos();
    } catch (error) {
      toast.error("Error al actualizar el estado de los gastos");
      console.error("Error:", error);
    }
  };

  const handleExportToExcel = async () => {
    try {
      const blob = await exportGastosProveedoresExcel(
        selectedProveedor,
        startDate,
        endDate
      );
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "gastos_proveedores.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
      toast.success("Exportación exitosa");
    } catch (error) {
      toast.error("Error al exportar los datos");
    }
  };

  return (
    <>
      <div className="flex justify-between items-start mb-4">
        <div className="flex flex-col space-y-2">
          <div className="flex space-x-2">
            <ProveedorFilter
              value={selectedProveedor}
              onChange={setSelectedProveedor}
            />
            <Select
              value={
                statusFilter === undefined ? "all" : statusFilter ? "1" : "0"
              }
              onValueChange={(value) =>
                setStatusFilter(
                  value === "all" ? undefined : value === "1" ? true : false
                )
              }
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Estado" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Estado</SelectLabel>
                  <SelectItem value="all">Todos (estado)</SelectItem>
                  <SelectItem value="0">Pendiente</SelectItem>
                  <SelectItem value="1">Pagado</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>

            <DateFilter
              startDate={startDate}
              endDate={endDate}
              onChangeStartDate={(date) => {
                if (date && (!endDate || date <= endDate)) {
                  setStartDate(date);
                } else {
                  toast.error(
                    "La fecha de inicio no puede ser mayor que la fecha de fin."
                  );
                }
              }}
              onChangeEndDate={(date) => {
                if (date && (!startDate || date >= startDate)) {
                  setEndDate(date);
                } else {
                  toast.error(
                    "La fecha de fin no puede ser menor que la fecha de inicio."
                  );
                }
              }}
              minDate={undefined}
              maxDate={undefined}
            />
            <Button
              variant="outline"
              onClick={() => {
                setStartDate(startOfDay(new Date()));
                setEndDate(endOfDay(new Date()));
              }}
            >
              Hoy
            </Button>
          </div>
          <div className="flex space-x-2">
            <Button
              variant="destructive"
              onClick={() => handleUpdateStatusClick(false)}
              disabled={table.getSelectedRowModel().rows.length === 0}
              className="mt-2"
            >
              Marcar como pendiente
            </Button>
            <Button
              variant="green"
              onClick={() => handleUpdateStatusClick(true)}
              disabled={table.getSelectedRowModel().rows.length === 0}
              className="mt-2"
            >
              Marcar como pagado
            </Button>
            {/* Botón para exportar a Excel */}
            <Button
              variant="outline"
              onClick={handleExportToExcel}
              className="mt-2"
              disabled={
                isLoading ||
                (!selectedProveedor &&
                  !startDate &&
                  !endDate &&
                  statusFilter === undefined)
              }
            >
              Exportar a Excel
            </Button>
          </div>
        </div>
        <Card className="w-[350px] ml-auto">
          <CardHeader>
            <CardTitle className="flex flex-col">
              <span>Total filtrado</span>
              <span className="text-sm font-normal text-muted-foreground">
                {formatDateRange(startDate, endDate)}
              </span>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-right mt-4 font-semibold">
              $
              <MotionNumber
                value={totalValue}
                format={{
                  style: "currency",
                  currency: "ARS",
                  minimumFractionDigits: 2,
                }}
                locales="es-ES"
                className="ml-1 text-2xl"
              />
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="rounded-md border overflow-x-auto">
        <UITable key={pagination.pageIndex}>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup, groupIndex) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header, headerIndex) => (
                  <TableHead key={`${groupIndex}_${headerIndex}`}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  <Spinner />
                </TableCell>
              </TableRow>
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, rowIndex) => (
                <TableRow
                  key={row.original.gasto_proveedor_id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell, cellIndex) => (
                    <TableCell key={`${rowIndex}_${cellIndex}`}>
                      {cell.column.id === "gastoInsertedAt"
                        ? (() => {
                            const dateValue = cell.getValue() as string;
                            return formatDate(dateValue);
                          })()
                        : flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No se han encontrado gastos con los criterios de búsqueda
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </UITable>
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getSelectedRowModel().rows.length} de{" "}
          {table.getFilteredRowModel().rows.length} fila(s) seleccionadas.
        </div>
      </div>
      <GastosProveedoresDataTablePagination
        table={table}
        pageCount={pageCount}
      />
    </>
  );
}
