import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { toast } from "sonner";
import {
  getCompanies,
  getSegments,
  getSiniestrosByFilters,
  getPromedioCierreMensual,
} from "@/services/siniestroService";
import {
  Bar,
  BarChart,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
  LabelList,
} from "recharts";
import { HiChartPie, HiChartBar, HiLocationMarker } from "react-icons/hi";
import { useAuth } from "@/contexts/AuthContext";
import { StatsCard } from "./StatsCard";
import { PromedioLineChart } from "./PromedioLineChart";
import { MainFilter } from "./MainFilter";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

interface Company {
  id: number;
  name: string;
}

interface ItemType {
  id: number;
  name: string;
}

interface Filters {
  companyId: string | undefined;
  itemTypeName: string;
  startDate: Date | null;
  endDate: Date | null;
}

interface ZipcodeStat {
  cps: any;
  cp: string;
  locality: string;
  total: number;
  marcas: string;
}

// Definir una interfaz específica para las estadísticas
interface SiniestroStats {
  diasHastaCierre: null;
  id: number;
  state_id: number;
  marca: string;
  zipcode: string | null;
  locality: string | null;
  causa: string | null;
  resolucion: string | null;
  tipoPago: string | null;
  segmento: string;
  insertedAt: string;
  closedAt: string | null;
  esTelefonico: number;
}

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884d8",
  "#82ca9d",
];

const getTotalCauses = (stats: any[]) => {
  const totals = stats.reduce(
    (acc, curr) => {
      return {
        caidas: acc.caidas + curr.causes.caidas,
        rayos: acc.rayos + curr.causes.rayos,
        tension: acc.tension + curr.causes.tension,
        otros: acc.otros + curr.causes.otros,
      };
    },
    { caidas: 0, rayos: 0, tension: 0, otros: 0 }
  );

  return [
    { name: "Caída", value: totals.caidas },
    { name: "Rayo", value: totals.rayos },
    { name: "Tensión", value: totals.tension },
    { name: "Otros", value: totals.otros },
  ]
    .filter((item) => item.value > 0)
    .sort((a, b) => b.value - a.value); // Ordenar de mayor a menor
};

const getTotalPaymentTypes = (stats: any[]) => {
  const totals = stats.reduce(
    (acc, curr) => ({
      cheque: acc.cheque + (curr.paymentTypes?.cheque || 0),
      ordenCompra: acc.ordenCompra + (curr.paymentTypes?.ordenCompra || 0),
      transferencia:
        acc.transferencia + (curr.paymentTypes?.transferencia || 0),
      garbarino: acc.garbarino + (curr.paymentTypes?.garbarino || 0),
      falabella: acc.falabella + (curr.paymentTypes?.falabella || 0),
      fravega: acc.fravega + (curr.paymentTypes?.fravega || 0),
      authogar: acc.authogar + (curr.paymentTypes?.authogar || 0),
      musimundo: acc.musimundo + (curr.paymentTypes?.musimundo || 0),
      mercadoPago: acc.mercadoPago + (curr.paymentTypes?.mercadoPago || 0),
      reposicionAllianz:
        acc.reposicionAllianz + (curr.paymentTypes?.reposicionAllianz || 0),
      reposicionTuService:
        acc.reposicionTuService + (curr.paymentTypes?.reposicionTuService || 0),
      otros: acc.otros + (curr.paymentTypes?.otros || 0),
    }),
    {
      cheque: 0,
      ordenCompra: 0,
      transferencia: 0,
      garbarino: 0,
      falabella: 0,
      fravega: 0,
      authogar: 0,
      musimundo: 0,
      mercadoPago: 0,
      reposicionAllianz: 0,
      reposicionTuService: 0,
      otros: 0,
    }
  );

  const result = [
    { name: "Cheque", value: totals.cheque },
    { name: "Orden de compra", value: totals.ordenCompra },
    { name: "Transferencia", value: totals.transferencia },
    { name: "Garbarino", value: totals.garbarino },
    { name: "Falabella", value: totals.falabella },
    { name: "Frávega", value: totals.fravega },
    { name: "Authogar", value: totals.authogar },
    { name: "Musimundo", value: totals.musimundo },
    { name: "MercadoPago", value: totals.mercadoPago },
    { name: "Rep. Allianz", value: totals.reposicionAllianz },
    { name: "Rep. Tu Service", value: totals.reposicionTuService },
    { name: "Otros", value: totals.otros },
  ]
    .filter((item) => item.value > 0)
    .sort((a, b) => b.value - a.value); // Ordenar de mayor a menor

  return result;
};

const getTotalResolutions = (stats: any[]) => {
  const totals = stats.reduce(
    (acc, curr) => ({
      rechazado: acc.rechazado + (curr.resolutions?.rechazados || 0),
      reparado: acc.reparado + (curr.resolutions?.reparados || 0),
      reingresoGarantia:
        acc.reingresoGarantia + (curr.resolutions?.reingresos || 0),
      desistido: acc.desistido + (curr.resolutions?.desistidos || 0),
      reposicionAllianz:
        acc.reposicionAllianz +
        (Array.isArray(curr.resolutions?.reposicionAllianz)
          ? curr.resolutions.reposicionAllianz.reduce(
              (a: number, b: number) => a + b,
              0
            )
          : curr.resolutions?.reposicionAllianz || 0),
      reposicionAsegurado:
        acc.reposicionAsegurado + (curr.resolutions?.reposicionAsegurado || 0),
      liquidado: acc.liquidado + (curr.resolutions?.liquidados || 0),
    }),
    {
      rechazado: 0,
      reparado: 0,
      reingresoGarantia: 0,
      desistido: 0,
      reposicionAllianz: 0,
      reposicionAsegurado: 0,
      liquidado: 0,
    }
  );

  return [
    { name: "Rechazado", value: totals.rechazado },
    { name: "Reparado", value: totals.reparado },
    { name: "Reingreso Garantía", value: totals.reingresoGarantia },
    { name: "Desistido", value: totals.desistido },
    { name: "Rep. Allianz", value: totals.reposicionAllianz },
    { name: "Rep. Asegurado", value: totals.reposicionAsegurado },
    { name: "Liquidado", value: totals.liquidado },
  ]
    .filter((item) => item.value > 0)
    .sort((a, b) => b.value - a.value); // Ordenar de mayor a menor
};

const CITY_GROUPS = {
  CABA: {
    pattern: (cp: number) =>
      (cp >= 1000 && cp <= 1499) ||
      [1601, 1602, 1603, 1605, 1606, 1607, 1608, 1609].includes(cp),
    name: "CABA",
    aliases: ["CAPITAL FEDERAL", "CABA", "BUENOS AIRES"],
  },
  "MAR DEL PLATA": {
    pattern: (cp: number) => cp >= 7600 && cp <= 7614,
    name: "MAR DEL PLATA",
    aliases: [
      "MAR  DEL  PLATA",
      "Mar del Plata",
      "CONSTITUCION, MAR DEL PLATA",
    ],
  },
  // ... puedes agregar más grupos según necesites
};

const SiniestrosStatsPage = () => {
  const [filters, setFilters] = useState<Filters>({
    companyId: undefined,
    itemTypeName: "Todos",
    startDate: null,
    endDate: null,
  });
  const [companies, setCompanies] = useState<Company[]>([]);
  const [itemTypes, setItemTypes] = useState<ItemType[]>([]);
  const [brandStats, setBrandStats] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStartYear, setSelectedStartYear] = useState<number>(
    new Date().getFullYear()
  );
  const [selectedStartMonth, setSelectedStartMonth] = useState<number>(
    new Date().getMonth() + 1
  );
  const [selectedEndYear, setSelectedEndYear] = useState<number>(
    new Date().getFullYear()
  );
  const [selectedEndMonth, setSelectedEndMonth] = useState<number>(
    new Date().getMonth() + 1
  );
  const [zipcodeStats, setZipcodeStats] = useState<ZipcodeStat[]>([]);
  const { roles } = useAuth();
  const hasRole7 = roles.includes(7);
  const [kpis, setKpis] = useState({
    promedioDiasCierre: 0,
    totalSiniestros: 0,
    siniestrosCerrados: 0,
    porcentajeCerrados: 0,
  });
  const [promedioData, setPromedioData] = useState<any[]>([]);
  const [isLoadingPromedio, setIsLoadingPromedio] = useState(false);

  const loadPromedioMensual = async () => {
    setIsLoadingPromedio(true);
    try {
      const response = await getPromedioCierreMensual({
        year: selectedEndYear.toString(),
        ...(filters.companyId && filters.companyId !== "Todos"
          ? { companyId: filters.companyId.toString() }
          : {}),
      });

      setPromedioData(response);
    } catch (error) {
      console.error("Error loading promedio mensual:", error);
      toast.error("Error al cargar el promedio mensual");
    } finally {
      setIsLoadingPromedio(false);
    }
  };

  useEffect(() => {
    loadPromedioMensual();
  }, [filters.companyId, selectedEndYear]);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setIsLoading(true);

        // Obtener el mes anterior
        const today = new Date();
        const lastMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );

        // Establecer el período inicial (mes anterior)
        const initialStartYear = lastMonth.getFullYear();
        const initialStartMonth = lastMonth.getMonth() + 1;
        const initialEndYear = lastMonth.getFullYear();
        const initialEndMonth = lastMonth.getMonth() + 1;

        setSelectedStartYear(initialStartYear);
        setSelectedStartMonth(initialStartMonth);
        setSelectedEndYear(initialEndYear);
        setSelectedEndMonth(initialEndMonth);

        // Cargar datos iniciales
        const [companiesData, segmentsData] = await Promise.all([
          getCompanies(),
          getSegments(),
        ]);

        setCompanies(companiesData);
        setItemTypes([{ id: 0, name: "Todos" }, ...segmentsData]);

        // Usar handleApplyFilters para la carga inicial
        await handleApplyFilters({
          startYear: initialStartYear,
          startMonth: initialStartMonth,
          endYear: initialEndYear,
          endMonth: initialEndMonth,
        });
      } catch (error) {
        console.error("Error loading data:", error);
        toast.error("Error al cargar los datos");
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialData();
  }, []); // Solo se ejecuta una vez al montar el componente

  const handleFilterChange = (field: string, value: any) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  const handlePeriodChange = (period: string) => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1;

    switch (period) {
      case "currentMonth":
        setSelectedStartYear(currentYear);
        setSelectedStartMonth(currentMonth);
        setSelectedEndYear(currentYear);
        setSelectedEndMonth(currentMonth);
        break;
      case "lastMonth":
        const lastMonth = currentMonth === 1 ? 12 : currentMonth - 1;
        const lastMonthYear =
          currentMonth === 1 ? currentYear - 1 : currentYear;
        setSelectedStartYear(lastMonthYear);
        setSelectedStartMonth(lastMonth);
        setSelectedEndYear(lastMonthYear);
        setSelectedEndMonth(lastMonth);
        break;
      case "currentQuarter":
        const currentQuarter = Math.ceil(currentMonth / 3);
        setSelectedStartYear(currentYear);
        setSelectedStartMonth((currentQuarter - 1) * 3 + 1);
        setSelectedEndYear(currentYear);
        setSelectedEndMonth(currentQuarter * 3);
        break;
      case "lastQuarter":
        const lastQuarter =
          currentMonth <= 3 ? 4 : Math.ceil(currentMonth / 3) - 1;
        const lastQuarterYear =
          currentMonth <= 3 ? currentYear - 1 : currentYear;
        setSelectedStartYear(lastQuarterYear);
        setSelectedStartMonth((lastQuarter - 1) * 3 + 1);
        setSelectedEndYear(lastQuarterYear);
        setSelectedEndMonth(lastQuarter * 3);
        break;
      case "currentYear":
        setSelectedStartYear(currentYear);
        setSelectedStartMonth(1);
        setSelectedEndYear(currentYear);
        setSelectedEndMonth(12);
        break;
      case "lastYear":
        setSelectedStartYear(currentYear - 1);
        setSelectedStartMonth(1);
        setSelectedEndYear(currentYear - 1);
        setSelectedEndMonth(12);
        break;
    }
  };

  const handleApplyFilters = async (initialDates?: {
    startYear: number;
    startMonth: number;
    endYear: number;
    endMonth: number;
  }) => {
    try {
      setIsLoading(true);

      // Usar las fechas proporcionadas o las del estado
      const startYear = initialDates?.startYear || selectedStartYear;
      const startMonth = initialDates?.startMonth || selectedStartMonth;
      const endYear = initialDates?.endYear || selectedEndYear;
      const endMonth = initialDates?.endMonth || selectedEndMonth;

      const startDate = `${startYear}-${String(startMonth).padStart(
        2,
        "0"
      )}-01`;
      const endDate = `${endYear}-${String(endMonth).padStart(2, "0")}-${String(
        new Date(endYear, endMonth, 0).getDate()
      ).padStart(2, "0")}`;

      const itemTypeNameFilter =
        filters.itemTypeName === "Todos" ? undefined : filters.itemTypeName;

      const response = await getSiniestrosByFilters({
        startDate,
        endDate,
        companyId: filters.companyId ? parseInt(filters.companyId) : undefined,
        itemTypeName: itemTypeNameFilter,
      });

      if (response?.siniestros?.siniestros) {
        const siniestrosData = response.siniestros.siniestros;
        const brandData = processBrandStats(siniestrosData);
        const zipcodeData = processZipcodeStats(siniestrosData);

        setBrandStats(brandData);
        setZipcodeStats(zipcodeData);
        setKpis({
          promedioDiasCierre: Number(
            response.siniestros.promedioDiasCierre || 0
          ),
          totalSiniestros: response.siniestros.totalSiniestros,
          siniestrosCerrados: response.siniestros.totalCerrados,
          porcentajeCerrados: response.siniestros.totalSiniestros
            ? (response.siniestros.totalCerrados /
                response.siniestros.totalSiniestros) *
              100
            : 0,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error al obtener los datos");
    } finally {
      setIsLoading(false);
    }
  };

  const processBrandStats = (siniestros: SiniestroStats[]) => {
    const brandMap = new Map();

    siniestros.forEach((siniestro) => {
      const brand = siniestro.marca?.toLowerCase() || "sin marca";
      if (!brandMap.has(brand)) {
        brandMap.set(brand, {
          brandName: siniestro.marca,
          total: 0,
          causes: { caidas: 0, rayos: 0, tension: 0, otros: 0 },
          resolutions: {
            rechazados: 0,
            liquidados: 0,
            reparados: 0,
            reingresos: 0,
            desistidos: 0,
            reposicionAllianz: 0,
            reposicionAsegurado: 0,
          },
          paymentTypes: {
            cheque: 0,
            ordenCompra: 0,
            transferencia: 0,
            garbarino: 0,
            falabella: 0,
            fravega: 0,
            authogar: 0,
            musimundo: 0,
            mercadoPago: 0,
            reposicionAllianz: 0,
            reposicionTuService: 0,
            otros: 0,
          },
        });
      }

      const stats = brandMap.get(brand);
      stats.total++;

      // Procesar causa
      if (siniestro.causa) {
        const causa = siniestro.causa.toLowerCase();
        if (causa.includes("caida") || causa.includes("caída")) {
          stats.causes.caidas++;
        } else if (causa.includes("rayo")) {
          stats.causes.rayos++;
        } else if (causa.includes("tension") || causa.includes("tensión")) {
          stats.causes.tension++;
        } else {
          stats.causes.otros++;
        }
      }

      // Procesar resolución
      if (siniestro.resolucion) {
        const resolucion = siniestro.resolucion.toLowerCase();
        switch (resolucion) {
          case "rechazado":
            stats.resolutions.rechazados++;
            break;
          case "liquidado":
            stats.resolutions.liquidados++;
            break;
          case "reparado":
            stats.resolutions.reparados++;
            break;
          case "reingreso garantia":
          case "reingreso garantía":
            stats.resolutions.reingresos++;
            break;
          case "desistido":
            stats.resolutions.desistidos++;
            break;
          case "rep. allianz":
          case "reposicion allianz":
          case "reposición allianz":
            stats.resolutions.reposicionAllianz++;
            break;
          case "rep. asegurado":
          case "reposicion asegurado":
          case "reposición asegurado":
            stats.resolutions.reposicionAsegurado++;
            break;
        }
      }

      // Procesar tipo de pago
      if (siniestro.tipoPago) {
        const tipoPago = String(siniestro.tipoPago).toLowerCase();
        switch (tipoPago) {
          case "1":
          case "cheque":
            stats.paymentTypes.cheque++;
            break;
          case "2":
          case "orden de compra":
            stats.paymentTypes.ordenCompra++;
            break;
          case "3":
          case "transferencia":
            stats.paymentTypes.transferencia++;
            break;
          case "5":
          case "garbarino":
            stats.paymentTypes.garbarino++;
            break;
          case "6":
          case "falabella":
            stats.paymentTypes.falabella++;
            break;
          case "7":
          case "fravega":
          case "frávega":
            stats.paymentTypes.fravega++;
            break;
          case "8":
          case "authogar":
            stats.paymentTypes.authogar++;
            break;
          case "9":
          case "musimundo":
            stats.paymentTypes.musimundo++;
            break;
          case "10":
          case "mercadopago":
            stats.paymentTypes.mercadoPago++;
            break;
          case "11":
          case "reposicion directa allianz":
          case "reposición directa allianz":
            stats.paymentTypes.reposicionAllianz++;
            break;
          case "12":
          case "reposicion directa tu service":
          case "reposición directa tu service":
            stats.paymentTypes.reposicionTuService++;
            break;
          default:
            stats.paymentTypes.otros++;
            break;
        }
      }
    });

    return Array.from(brandMap.values());
  };

  const processZipcodeStats = (siniestros: SiniestroStats[]) => {
    const zipcodeMap = new Map();

    // Función helper para normalizar nombres de localidades
    const normalizeLocality = (locality: string) => {
      return locality.trim().toUpperCase().replace(/\s+/g, " ");
    };

    siniestros.forEach((siniestro) => {
      if (
        !siniestro.zipcode ||
        siniestro.zipcode === "0" ||
        siniestro.zipcode === "0000" ||
        !siniestro.locality
      )
        return;

      const cp = siniestro.zipcode;
      const cpNum = parseInt(cp);
      const normalizedLocality = normalizeLocality(siniestro.locality);

      // Determinar el grupo de ciudad
      let key = cp;
      let displayName = normalizedLocality;
      let cityGroup = null;

      // Buscar coincidencias en los grupos de ciudades
      for (const [city, config] of Object.entries(CITY_GROUPS)) {
        if (
          config.pattern(cpNum) ||
          config.aliases.includes(normalizedLocality)
        ) {
          key = city;
          displayName = config.name;
          cityGroup = city;
          break;
        }
      }

      if (!zipcodeMap.has(key)) {
        zipcodeMap.set(key, {
          cp: key,
          locality: displayName,
          total: 0,
          marcas: new Set(),
          cps: new Set(),
          originalLocality: normalizedLocality,
        });
      }

      const stats = zipcodeMap.get(key);
      stats.total++;
      if (siniestro.marca) stats.marcas.add(siniestro.marca);
      if (cityGroup) {
        stats.cps.add(cp);
      }
    });

    return Array.from(zipcodeMap.values())
      .map((stat) => ({
        ...stat,
        marcas: Array.from(stat.marcas).join(", "),
        locality:
          stat.cps?.size > 0
            ? `${stat.locality} (${stat.cps.size} CP)`
            : stat.originalLocality,
        displayName:
          stat.cps?.size > 0 ? `${stat.locality}` : stat.originalLocality,
        cps: stat.cps?.size > 0 ? Array.from(stat.cps).sort() : undefined,
        total: stat.total,
      }))
      .sort((a, b) => b.total - a.total);
  };

  const processBrandDataForChart = (data: any[], limit = 20) => {
    // Ordenar por total descendente
    const sortedData = [...data].sort((a, b) => b.total - a.total);

    if (sortedData.length <= limit) {
      return sortedData;
    }

    // Tomar los primeros {limit} elementos
    const topBrands = sortedData.slice(0, limit);

    // Sumar el resto
    const otherBrands = sortedData.slice(limit);
    const othersTotal = otherBrands.reduce(
      (sum, brand) => sum + brand.total,
      0
    );

    // Agregar la categoría "Otros"
    topBrands.push({
      brandName: `Otros (${otherBrands.length} marcas)`,
      total: othersTotal,
    });

    return topBrands;
  };

  if (isLoading) {
    return (
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">Estadísticas de Siniestros</h1>
        <div className="grid gap-4">
          <Skeleton className="h-[200px]" />
          <Skeleton className="h-[400px]" />
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6 p-6">
      <Tabs defaultValue="stats" className="w-full">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="stats">Estadísticas Generales</TabsTrigger>
          <TabsTrigger value="monthly">Promedio de Cierre Mensual</TabsTrigger>
        </TabsList>

        <TabsContent value="stats">
          <MainFilter
            companies={companies}
            itemTypes={itemTypes}
            filters={filters}
            selectedStartYear={selectedStartYear}
            selectedStartMonth={selectedStartMonth}
            selectedEndYear={selectedEndYear}
            selectedEndMonth={selectedEndMonth}
            onFilterChange={handleFilterChange}
            onStartYearChange={(year) => setSelectedStartYear(Number(year))}
            onStartMonthChange={(month) => setSelectedStartMonth(Number(month))}
            onEndYearChange={(year) => setSelectedEndYear(Number(year))}
            onEndMonthChange={(month) => setSelectedEndMonth(Number(month))}
            onPeriodChange={handlePeriodChange}
            onApplyFilters={handleApplyFilters}
            hasRole7={hasRole7}
          />

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <StatsCard
              title="Promedio de cierre"
              value={kpis.promedioDiasCierre}
              unit="días"
              decimals={1}
            />
            <StatsCard
              title="Total siniestros"
              value={kpis.totalSiniestros}
              unit="siniestros"
              decimals={0}
            />
            <StatsCard
              title="Siniestros cerrados"
              value={kpis.siniestrosCerrados}
              unit="siniestros"
              decimals={0}
            />
            <StatsCard
              title="% Cerrados"
              value={kpis.porcentajeCerrados}
              unit="%"
              decimals={1}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
            {/* Gráfico de Barras por Marca */}
            <Card className="col-span-1 md:col-span-3">
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <HiChartBar className="h-6 w-6 text-blue-500" />
                  Total por Marca (Top 20)
                </CardTitle>
              </CardHeader>
              <CardContent className="h-[400px]">
                {isLoading ? (
                  <Skeleton className="w-full h-full" />
                ) : brandStats.length > 0 ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={processBrandDataForChart(brandStats)}>
                      <XAxis dataKey="brandName" height={100} fontSize={12} />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="total" fill="#8884d8" name="Total" />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <p className="text-center text-muted-foreground">
                    No hay datos disponibles
                  </p>
                )}
              </CardContent>
            </Card>

            {/* Card combinada para Causas y Resoluciones */}
            <Card className="col-span-1 md:col-span-3">
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <HiChartPie className="h-6 w-6 text-purple-500" />
                  Distribución por causa, resolución y tipos de pago
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                  {/* Gráfico de Causas */}
                  <div className="h-[500px] pt-4 md:pt-0">
                    <h3 className="text-center font-semibold mb-4">Causas</h3>
                    <div className="h-[450px]">
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={getTotalCauses(brandStats)}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="35%" // Ajustado de 40% a 35% para dar más espacio a la leyenda
                            innerRadius={50}
                            outerRadius={90} // Reducido de 100 a 90
                          >
                            {getTotalCauses(brandStats).map((_, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                          <Legend
                            layout="vertical"
                            align="center"
                            verticalAlign="bottom"
                            wrapperStyle={{
                              paddingTop: "20px",
                              maxHeight: "200px",
                            }}
                            formatter={(_, entry: any) => {
                              const percentage = (
                                (entry.payload.value /
                                  getTotalCauses(brandStats).reduce(
                                    (acc, curr) => acc + curr.value,
                                    0
                                  )) *
                                100
                              ).toFixed(1);
                              return `${entry.payload.name} (${percentage}%)`;
                            }}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  {/* Gráfico de Resoluciones - aplicar los mismos cambios */}
                  <div className="h-[500px] pt-4 md:pt-0">
                    <h3 className="text-center font-semibold mb-4">
                      Resoluciones
                    </h3>
                    <div className="h-[450px]">
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={getTotalResolutions(brandStats)}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="35%"
                            innerRadius={50}
                            outerRadius={90}
                          >
                            {getTotalResolutions(brandStats).map((_, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                          <Legend
                            layout="vertical"
                            align="center"
                            verticalAlign="bottom"
                            wrapperStyle={{
                              paddingTop: "20px",
                              maxHeight: "200px",
                            }}
                            formatter={(_, entry: any) => {
                              const percentage = (
                                (entry.payload.value /
                                  getTotalResolutions(brandStats).reduce(
                                    (acc, curr) => acc + curr.value,
                                    0
                                  )) *
                                100
                              ).toFixed(1);
                              return `${entry.payload.name} (${percentage}%)`;
                            }}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  {/* Gráfico de Tipos de Pago - aplicar los mismos cambios */}
                  <div className="h-[500px] pt-4 md:pt-0">
                    <h3 className="text-center font-semibold mb-4">
                      Tipos de Pago
                    </h3>
                    <div className="h-[450px]">
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={getTotalPaymentTypes(brandStats)}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="35%"
                            innerRadius={50}
                            outerRadius={90}
                          >
                            {getTotalPaymentTypes(brandStats).map(
                              (_, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              )
                            )}
                          </Pie>
                          <Tooltip />
                          <Legend
                            layout="vertical"
                            align="center"
                            verticalAlign="bottom"
                            wrapperStyle={{
                              paddingTop: "20px",
                              maxHeight: "200px",
                            }}
                            formatter={(_, entry: any) => {
                              const percentage = (
                                (entry.payload.value /
                                  getTotalPaymentTypes(brandStats).reduce(
                                    (acc, curr) => acc + curr.value,
                                    0
                                  )) *
                                100
                              ).toFixed(1);
                              return `${entry.payload.name} (${percentage}%)`;
                            }}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>

          <Card className="col-span-1 md:col-span-3 mt-6">
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <HiLocationMarker className="h-6 w-6 text-blue-500" />
                Distribución por Código Postal (Top 20)
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-[400px]">
                {isLoading ? (
                  <Skeleton className="w-full h-full" />
                ) : zipcodeStats.length > 0 ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={zipcodeStats
                        .sort((a, b) => b.total - a.total)
                        .slice(0, 20)}
                    >
                      <XAxis
                        dataKey="cp"
                        label={{ value: "Código Postal", position: "bottom" }}
                        tickFormatter={(value) => {
                          const stat = zipcodeStats.find((s) => s.cp === value);
                          return stat?.locality || value;
                        }}
                        angle={-45}
                        textAnchor="end"
                        height={150}
                        interval={0}
                        fontSize={11}
                      />
                      <YAxis
                        label={{
                          value: "Cantidad",
                          angle: -90,
                          position: "insideLeft",
                        }}
                      />
                      <Tooltip
                        formatter={(value, _name, props) => {
                          const stat = zipcodeStats.find(
                            (s) => s.cp === props.payload.cp
                          );
                          if (stat?.cps?.length > 0) {
                            return [
                              `${value} siniestros`,
                              `${stat?.locality} (${stat?.cps?.length} códigos postales)`,
                            ];
                          }
                          return [
                            `${value} siniestros`,
                            stat?.locality || stat?.cp,
                          ];
                        }}
                      />
                      <Bar
                        dataKey="total"
                        fill="#8884d8"
                        name="Cantidad"
                        maxBarSize={50}
                      >
                        <LabelList
                          dataKey="total"
                          position="top"
                          style={{ fontSize: "12px" }}
                          offset={5}
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <p className="text-center text-muted-foreground">
                    No hay datos disponibles
                  </p>
                )}
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="monthly">
          <Card>
            <CardHeader>
              <CardTitle>Promedio de cierre mensual</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
                  {!hasRole7 ? (
                    <select
                      value={filters.companyId}
                      onChange={(e) =>
                        handleFilterChange("companyId", e.target.value)
                      }
                      className="w-full md:w-auto"
                    >
                      <option value="">Todas las compañías</option>
                      {companies.map((company) => (
                        <option key={company.id} value={company.id}>
                          {company.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="text-sm text-muted-foreground">
                      {
                        companies.find(
                          (c) => c.id.toString() === filters.companyId
                        )?.name
                      }
                    </div>
                  )}
                </div>

                <PromedioLineChart
                  data={promedioData}
                  isLoading={isLoadingPromedio}
                  selectedYear={selectedEndYear}
                  onYearChange={(year) => setSelectedEndYear(Number(year))}
                />
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default SiniestrosStatsPage;
