import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import { MaterialSymbolsAddChartOutline } from "@/components/icons/MaterialSymbolsAddChartOutline";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { formattedValueARS } from "../SiniestroDetails/formattedValue";
import { ReloadIcon } from "@radix-ui/react-icons";

interface AddIngresoDialogProps {
  onSave: (newData: { text: string; value: number }) => Promise<void>;
  disabled?: boolean;
  predefinedHonorarios: PredefinedHonorario[];
}

export function AddIngresoDialog({
  onSave,
  disabled,
  predefinedHonorarios,
}: AddIngresoDialogProps) {
  const [textInput, setTextInput] = useState("");
  const [valueInput, setValueInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const newData = {
        text: textInput,
        value: Number(valueInput),
      };
      await onSave(newData);

      setTextInput("");
      setValueInput("");
      setIsOpen(false);
    } catch (error) {
      // Manejar error si es necesario
    } finally {
      setIsSaving(false);
    }
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Permite solo números enteros
    if (/^\d*$/.test(value)) {
      setValueInput(value);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          variant="green"
          onClick={() => setIsOpen(true)}
          disabled={disabled}
        >
          <MaterialSymbolsAddChartOutline className="w-5 h-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Agregar honorario</DialogTitle>
          <DialogDescription>
            Ingresa los detalles del nuevo honorario y haz clic en guardar.
          </DialogDescription>
        </DialogHeader>

        <Select
          onValueChange={(value) => {
            const honorario = predefinedHonorarios.find(
              (h: PredefinedHonorario) => h.id === parseInt(value)
            );
            if (honorario) {
              setTextInput(honorario.description);
              setValueInput(honorario.amount.toString());
            }
          }}
        >
          <SelectTrigger>
            <SelectValue
              placeholder={
                predefinedHonorarios.length > 0
                  ? "Seleccione un honorario predefinido"
                  : "No hay honorarios predefinidos"
              }
            />
          </SelectTrigger>
          <SelectContent>
            {predefinedHonorarios.length > 0 ? (
              predefinedHonorarios.map((honorario: PredefinedHonorario) => (
                <SelectItem key={honorario.id} value={honorario.id.toString()}>
                  {honorario.description} -{" "}
                  {formattedValueARS(honorario.amount)}
                </SelectItem>
              ))
            ) : (
              <SelectItem value="no-honorarios" disabled>
                Esta compañía no tiene honorarios predefinidos
              </SelectItem>
            )}
          </SelectContent>
        </Select>

        <div className="grid gap-4 py-4">
          <Label htmlFor="textInput">Tipo de honorario</Label>
          <Input
            id="textInput"
            value={textInput}
            placeholder="Ejemplo: Informe técnico"
            onChange={(e) => setTextInput(e.target.value)} // Permitir cualquier texto
          />
          <Label htmlFor="valueInput">Valor</Label>
          <Input
            id="valueInput"
            type="text" // Usar "text" para controlar manualmente el contenido
            value={valueInput}
            onChange={handleValueChange} // Manejar cambio para permitir solo números enteros
          />
        </div>
        <DialogFooter>
          <Button onClick={handleSave} disabled={isSaving}>
            {isSaving ? (
              <>
                <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                Guardando...
              </>
            ) : (
              "Guardar"
            )}
          </Button>
          <DialogClose asChild>
            <Button variant="secondary" disabled={isSaving}>
              Cerrar
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
