import React, { useEffect, useState } from "react";
import { getActiveSessions } from "../../services/userService";
import { motion } from "framer-motion";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { FaDesktop, FaClock, FaUser, FaNetworkWired } from "react-icons/fa";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface Session {
  id: number;
  user_id: number;
  username: string;
  login_time: string;
  ip_address: string;
  device_info: string;
  current_duration: number;
  last_activity: string;
  first_connection: string;
  last_connection: string;
}

const LoadingDots = () => (
  <div className="flex justify-center items-center h-64">
    <div className="space-x-2 flex items-center">
      {[0, 1, 2].map((index) => (
        <motion.div
          key={index}
          className="w-4 h-4 bg-blue-500 rounded-full"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.3, 1, 0.3],
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
            delay: index * 0.2,
          }}
        />
      ))}
    </div>
    <motion.p
      className="ml-4 text-lg text-gray-600"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      Cargando sesiones...
    </motion.p>
  </div>
);

export const ActiveSessions: React.FC = () => {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentTime, setCurrentTime] = useState(new Date());

  const formatDateTime = (dateString: string): string => {
    if (!dateString) return "";
    console.log("Formateando fecha:", dateString);
    const date = new Date(dateString);
    console.log("Fecha parseada:", date);
    // Ya no necesitamos ajustar la hora porque viene correcta del backend
    console.log("Fecha final:", date);

    return new Intl.DateTimeFormat("es-AR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).format(date);
  };

  const formatDuration = (loginTime: string): string => {
    console.log("Calculando duración para login_time:", loginTime);
    const start = new Date(loginTime);
    console.log("Fecha de inicio parseada:", start);
    const now = new Date(currentTime);
    console.log("Fecha actual:", now);

    const diffSeconds = Math.floor((now.getTime() - start.getTime()) / 1000);
    console.log("Diferencia en segundos:", diffSeconds);
    const hours = Math.floor(diffSeconds / 3600);
    const minutes = Math.floor((diffSeconds % 3600) / 60);
    const seconds = diffSeconds % 60;

    console.log("Duración calculada:", { hours, minutes, seconds });

    // Formatear con padding de ceros
    const hoursStr = hours.toString().padStart(2, "0");
    const minutesStr = minutes.toString().padStart(2, "0");
    const secondsStr = seconds.toString().padStart(2, "0");

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  };

  const fetchActiveSessions = async () => {
    try {
      const data = await getActiveSessions();
      console.log("Datos recibidos del backend:", data);
      setSessions(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : "Error al cargar sesiones");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActiveSessions();
    const fetchInterval = setInterval(fetchActiveSessions, 60000);
    const timeInterval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(fetchInterval);
      clearInterval(timeInterval);
    };
  }, []);

  if (loading) return <LoadingDots />;
  if (error)
    return (
      <motion.div
        className="text-red-500 text-center p-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Error: {error}
      </motion.div>
    );

  return (
    <div className="p-4">
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>
                <div className="flex items-center space-x-2">
                  <FaUser className="text-muted-foreground" />
                  <span>Usuario</span>
                </div>
              </TableHead>
              <TableHead>
                <div className="flex items-center space-x-2">
                  <FaClock className="text-muted-foreground" />
                  <span>Primera Conexión</span>
                </div>
              </TableHead>
              <TableHead>
                <div className="flex items-center space-x-2">
                  <FaClock className="text-muted-foreground" />
                  <span>Última Conexión</span>
                </div>
              </TableHead>
              <TableHead>
                <div className="flex items-center space-x-2">
                  <FaNetworkWired className="text-muted-foreground" />
                  <span>IP</span>
                </div>
              </TableHead>
              <TableHead>
                <div className="flex items-center space-x-2">
                  <FaDesktop className="text-muted-foreground" />
                  <span>Dispositivo</span>
                </div>
              </TableHead>
              <TableHead>
                <div className="flex items-center space-x-2">
                  <FaClock className="text-muted-foreground" />
                  <span>Duración</span>
                </div>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sessions.map((session) => (
              <TableRow key={session.id}>
                <TableCell className="font-medium">
                  {session.username}
                </TableCell>
                <TableCell>
                  {formatDateTime(session.first_connection)}
                </TableCell>
                <TableCell>{formatDateTime(session.last_connection)}</TableCell>
                <TableCell>{session.ip_address}</TableCell>
                <TableCell>
                  <TooltipProvider delayDuration={50}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="max-w-[300px] truncate cursor-default">
                          {session.device_info}
                        </div>
                      </TooltipTrigger>
                      <TooltipContent className="max-w-[400px] p-2">
                        {session.device_info.split(",").map((info, i) => (
                          <div key={i} className="py-0.5">
                            {info.trim()}
                          </div>
                        ))}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </TableCell>
                <TableCell className="font-mono">
                  {formatDuration(session.login_time)}
                </TableCell>
              </TableRow>
            ))}
            {sessions.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={6}
                  className="text-center py-8 text-gray-500"
                >
                  No hay sesiones activas
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
