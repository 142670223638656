import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from "../ui/card";
import { Badge } from "../ui/badge";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { ScrollArea } from "../ui/scroll-area";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Button } from "../ui/button";
import { motion } from "framer-motion";
import { ReloadIcon } from "@radix-ui/react-icons";

interface MensajesCardProps {
  mensajes: {
    id: number;
    usuario: number;
    fecha: string;
    mensaje: string;
    leido: string;
    tipo: string;
  }[];
  handleMarkAsRead: (notificacionId: number, leido: boolean) => void;
  totalMensajes: number; // total number of messages for pagination
  currentPage: number;
  onPageChange: (page: number) => void;
}

const MensajesCard: React.FC<MensajesCardProps> = ({
  mensajes,
  handleMarkAsRead,
  totalMensajes,
  currentPage,
  onPageChange,
}) => {
  const [loadingNotificationId, setLoadingNotificationId] = useState<
    number | null
  >(null);

  const extractSiniestroDetails = (mensaje: string) => {
    const match = mensaje.match(
      /(?:Nuevo comentario (?:del tramitador )?en siniestro #(\S+) \(ID: (\d+)\):)/
    );
    const companyIncidentNumber = match ? match[1] : null;
    const siniestroId = match ? match[2] : null;
    const mainMessage = match
      ? mensaje.split(":").slice(2).join(":").trim()
      : mensaje;

    return { siniestroId, companyIncidentNumber, mainMessage };
  };

  const handleMarkAsReadAndUpdate = async (
    notificacionId: number,
    leido: boolean
  ) => {
    setLoadingNotificationId(notificacionId);
    try {
      await handleMarkAsRead(notificacionId, leido);
    } finally {
      setLoadingNotificationId(null);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <PaginationItem key={i}>
            <PaginationLink
              href="#"
              isActive={currentPage === i}
              onClick={(e) => {
                e.preventDefault();
                onPageChange(i);
              }}
            >
              {i}
            </PaginationLink>
          </PaginationItem>
        );
      }
    } else {
      pageNumbers.push(
        <PaginationItem key={1}>
          <PaginationLink
            href="#"
            isActive={currentPage === 1}
            onClick={(e) => {
              e.preventDefault();
              onPageChange(1);
            }}
          >
            1
          </PaginationLink>
        </PaginationItem>
      );

      if (currentPage > 3) {
        pageNumbers.push(<PaginationEllipsis key="ellipsis1" />);
      }

      for (
        let i = Math.max(2, currentPage - 1);
        i <= Math.min(currentPage + 1, totalPages - 1);
        i++
      ) {
        pageNumbers.push(
          <PaginationItem key={i}>
            <PaginationLink
              href="#"
              isActive={currentPage === i}
              onClick={(e) => {
                e.preventDefault();
                onPageChange(i);
              }}
            >
              {i}
            </PaginationLink>
          </PaginationItem>
        );
      }

      if (currentPage < totalPages - 2) {
        pageNumbers.push(<PaginationEllipsis key="ellipsis2" />);
      }

      pageNumbers.push(
        <PaginationItem key={totalPages}>
          <PaginationLink
            href="#"
            isActive={currentPage === totalPages}
            onClick={(e) => {
              e.preventDefault();
              onPageChange(totalPages);
            }}
          >
            {totalPages}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return pageNumbers;
  };

  const pageSize = 10; // Number of items per page
  const totalPages = Math.ceil(totalMensajes / pageSize);

  return (
    <Card className="h-full">
      <CardHeader>
        <CardTitle className="text-lg font-bold">Mensajes</CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[500px] p-2">
          <div className="flex flex-col gap-2">
            {mensajes.map((notificacion) => {
              const { siniestroId, mainMessage, companyIncidentNumber } =
                extractSiniestroDetails(notificacion.mensaje);
              const isLoading = loadingNotificationId === notificacion.id;

              return (
                <ContextMenu key={notificacion.id}>
                  <ContextMenuTrigger className="flex flex-col w-full">
                    <motion.div
                      initial={false}
                      animate={{
                        opacity: isLoading ? 0.7 : 1,
                        scale: isLoading ? 0.98 : 1,
                      }}
                      transition={{ duration: 0.2 }}
                    >
                      <Card
                        className={`border p-2 transition-all hover:bg-accent ${
                          notificacion.leido === "1"
                            ? "bg-muted dark:bg-gray-800"
                            : "bg-white dark:bg-gray-700"
                        } relative`}
                      >
                        {isLoading && (
                          <motion.div
                            className="absolute inset-0 flex items-center justify-center bg-black/5 dark:bg-white/5 rounded-lg z-10"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.2 }}
                          >
                            <ReloadIcon className="h-6 w-6 animate-spin" />
                          </motion.div>
                        )}
                        <div className="flex justify-between items-start">
                          <div className="flex-1">
                            <CardTitle className="text-sm font-semibold">
                              <span className="font-bold text-lg dark:text-gray-200">
                                {companyIncidentNumber
                                  ? `Siniestro #${companyIncidentNumber}`
                                  : ""}
                              </span>
                              <br />
                              <span className="text-sm text-muted-foreground dark:text-gray-300">
                                {mainMessage}
                              </span>
                            </CardTitle>
                            <CardDescription className="text-xs text-muted-foreground dark:text-gray-400">
                              {format(parseISO(notificacion.fecha), "PPPPp", {
                                locale: es,
                              })}
                            </CardDescription>
                          </div>
                          <Badge
                            variant={
                              notificacion.leido === "0"
                                ? "destructive"
                                : "secondary"
                            }
                            className="ml-2 text-xs"
                          >
                            {notificacion.leido === "0" ? "No leído" : "Leído"}
                          </Badge>
                        </div>
                      </Card>
                    </motion.div>
                  </ContextMenuTrigger>
                  <ContextMenuContent className="w-48">
                    <ContextMenuItem
                      disabled={isLoading}
                      onSelect={() =>
                        handleMarkAsReadAndUpdate(
                          notificacion.id,
                          notificacion.leido === "0"
                        )
                      }
                    >
                      Marcar como{" "}
                      {notificacion.leido === "0" ? "leído" : "no leído"}
                    </ContextMenuItem>
                    <ContextMenuSeparator />
                    <ContextMenuItem
                      disabled={!siniestroId || isLoading}
                      onSelect={() => {
                        if (siniestroId) {
                          window.open(
                            `/siniestros/${siniestroId}/detalles`,
                            "_blank"
                          );
                        }
                      }}
                    >
                      Ir al siniestro
                    </ContextMenuItem>
                  </ContextMenuContent>
                </ContextMenu>
              );
            })}
          </div>
        </ScrollArea>
      </CardContent>
      <Pagination className="mb-4">
        <PaginationContent>
          <PaginationItem>
            <Button
              variant="outline"
              size="sm"
              onClick={() => onPageChange(1)}
              disabled={currentPage === 1}
            >
              Primera página
            </Button>
          </PaginationItem>
          <PaginationItem>
            <PaginationPrevious
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onPageChange(currentPage - 1);
              }}
              className={
                currentPage === 1 ? "pointer-events-none opacity-50" : ""
              }
            />
          </PaginationItem>
          {renderPageNumbers()}
          <PaginationItem>
            <PaginationNext
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onPageChange(currentPage + 1);
              }}
              className={
                currentPage === totalPages
                  ? "pointer-events-none opacity-50"
                  : ""
              }
            />
          </PaginationItem>
          <PaginationItem>
            <Button
              variant="outline"
              size="sm"
              onClick={() => onPageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              Última página
            </Button>
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </Card>
  );
};

export default MensajesCard;
