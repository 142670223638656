/* eslint-disable @typescript-eslint/await-thenable */

import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { ReloadIcon } from "@radix-ui/react-icons";
import { IconamoonDeliveryFastBold } from "@/components/icons/IconamoonDeliveryFastBold";
import { addAccionToSiniestro } from "@/services/siniestroService";
import { toast } from "sonner";

interface BienSelectionDialogProps {
  bienes: Bien[];
  onGeneratePdf: (bienes: Bien[]) => Promise<void>;
  siniestro: Siniestro;
}

export const BienSelectionEntregaDialog = ({
  bienes,
  onGeneratePdf,
  siniestro,
}: BienSelectionDialogProps) => {
  const [selectedBienes, setSelectedBienes] = useState<number[]>([]);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false); // Estado para el proceso de generación de PDF

  const toggleBien = (bienId: number) => {
    setSelectedBienes((prevSelectedBienes) =>
      prevSelectedBienes.includes(bienId)
        ? prevSelectedBienes.filter((id) => id !== bienId)
        : [...prevSelectedBienes, bienId]
    );
  };

  const handleGeneratePdf = async () => {
    setIsGeneratingPdf(true);

    const bienesToExport = bienes.filter((bien) =>
      selectedBienes.includes(bien.id)
    );

    try {
      await onGeneratePdf(bienesToExport);

      // Registrar la acción según el tipo de remito
      if (siniestro.type === "G") {
        // Si es garantía, usar acción de remito reparado
        await addAccionToSiniestro(siniestro.id, 602, null, null);
      } else {
        // Si no es garantía, usar acción de remito de rechazo
        await addAccionToSiniestro(siniestro.id, 601, null, null);
      }

      toast.success("PDF generado exitosamente");
    } catch (error) {
      toast.error("Error al generar el PDF");
    } finally {
      setIsGeneratingPdf(false);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="entrega">
          <IconamoonDeliveryFastBold className="mr-2 h-4 w-4" />
          Imprimir PDF remito de entrega
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Seleccione los bienes</DialogTitle>
          <DialogDescription>
            Seleccione los bienes que desea incluir en el remito de entrega.
          </DialogDescription>
        </DialogHeader>
        {bienes
          .filter((bien) => bien.resolutionName !== "Sin cobertura")
          .map((bien) => (
            <div key={bien.id} className="flex items-center justify-start">
              <Checkbox
                id={`bien-${bien.id}`}
                checked={selectedBienes.includes(bien.id)}
                onCheckedChange={() => {
                  toggleBien(bien.id);
                }}
              />
              <label htmlFor={`bien-${bien.id}`} className="ml-2">
                {bien.itemType} - {bien.brandName} - {bien.model} -{" "}
                {bien.serial}
              </label>
            </div>
          ))}
        {bienes.filter((bien) => bien.resolutionName !== "Sin cobertura")
          .length === 0 && (
          <p className="text-sm text-gray-500 text-center">
            No hay bienes disponibles para generar remito de entrega.
          </p>
        )}
        <DialogFooter>
          {selectedBienes.length === 0 && (
            <p className="text-sm text-red-500 mb-2">
              Por favor, seleccione al menos un bien.
            </p>
          )}
          <Button
            onClick={handleGeneratePdf}
            disabled={isGeneratingPdf || selectedBienes.length === 0}
          >
            {isGeneratingPdf ? (
              <>
                <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                Generando PDF...
              </>
            ) : (
              "Generar PDF"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
