import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth } from "./AuthContext";

interface MaintenanceContextType {
  isMaintenanceMode: boolean;
  toggleMaintenance: () => Promise<void>;
}

interface MaintenanceResponse {
  maintenance: boolean;
  message?: string;
}

const MaintenanceContext = createContext<MaintenanceContextType | undefined>(
  undefined
);

// Usar la misma configuración de BASE_URL que en tus otros archivos
const BASE_URL =
  import.meta.env.MODE === "production"
    ? import.meta.env.VITE_API_URL_PROD
    : import.meta.env.VITE_API_URL_DEV;

const maintenanceFetch = async <T,>(
  endpoint: string,
  options: RequestInit = {}
): Promise<T> => {
  const response = await fetch(`${BASE_URL}/api/maintenance${endpoint}`, {
    ...options,
    headers: {
      "Content-Type": "application/json",
      ...options.headers,
    },
    credentials: "include",
  });

  if (!response.ok) {
    if (response.status === 403) {
      // Manejar específicamente errores de autenticación
      throw new Error("Authentication required");
    }
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
};

export function MaintenanceProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const { isAuthenticated, isAuthChecked } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout;

    const fetchMaintenanceStatus = async () => {
      // Solo proceder si la autenticación está verificada y el usuario está autenticado
      if (isAuthChecked && isAuthenticated && !isInitialized) {
        // Dar tiempo a que la autenticación se establezca completamente
        timeoutId = setTimeout(async () => {
          try {
            const data = await maintenanceFetch<MaintenanceResponse>("");
            if (mounted) {
              setIsMaintenanceMode(data.maintenance);
              setIsInitialized(true);
            }
          } catch (error) {
            if (mounted) {
              console.error("Error fetching maintenance status:", error);
              setIsMaintenanceMode(false);
            }
          }
        }, 1000);
      }
    };

    fetchMaintenanceStatus();

    return () => {
      mounted = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isAuthChecked, isAuthenticated, isInitialized]);

  // Resetear el estado cuando el usuario se desautentica
  useEffect(() => {
    if (!isAuthenticated) {
      setIsInitialized(false);
      setIsMaintenanceMode(false);
    }
  }, [isAuthenticated]);

  const toggleMaintenance = async () => {
    try {
      const data = await maintenanceFetch<MaintenanceResponse>("/toggle", {
        method: "POST",
      });
      setIsMaintenanceMode(data.maintenance);
    } catch (error) {
      console.error("Error toggling maintenance mode:", error);
      throw error;
    }
  };

  return (
    <MaintenanceContext.Provider
      value={{ isMaintenanceMode, toggleMaintenance }}
    >
      {children}
    </MaintenanceContext.Provider>
  );
}

export const useMaintenance = () => {
  const context = useContext(MaintenanceContext);
  if (context === undefined) {
    throw new Error("useMaintenance must be used within a MaintenanceProvider");
  }
  return context;
};
