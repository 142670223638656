import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { generarNotaCredito } from "@/services/facturaService";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

type RubroType = "Servicios" | "Productos";

export interface NotaCredito {
  id: number;
  numero: string;
  fecha: string;
  monto: number;
  motivo: string;
  facturaAsociada: {
    id: number;
    numero: string;
  };
}

interface GenerarNotaCreditoDialogProps {
  compañiaAseguradora: string;
}

export function GenerarNotaCreditoDialog({
  compañiaAseguradora,
}: GenerarNotaCreditoDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [facturaId, setFacturaId] = useState("");
  const [monto, setMonto] = useState("");
  const [motivo, setMotivo] = useState("");
  const [rubro, setRubro] = useState<RubroType>("Servicios");

  const handleRubroChange = (value: RubroType) => {
    setRubro(value);
  };

  const handleGenerarNotaCredito = async () => {
    setIsLoading(true);
    try {
      const response = await generarNotaCredito(
        parseInt(facturaId),
        parseFloat(monto),
        motivo,
        compañiaAseguradora,
        rubro
      );

      toast.success("Nota de crédito generada con éxito");
      setIsOpen(false);

      if (response.comprobante_pdf_url) {
        window.open(response.comprobante_pdf_url, "_blank");
      } else {
        console.warn("No se recibió la URL del PDF en la respuesta");
        toast.error("No se pudo descargar el PDF de la nota de crédito");
      }
    } catch (error) {
      console.error("Error detallado:", error);
      // Mostrar el mensaje de error exactamente como viene
      toast.error(error instanceof Error ? error.message : "Error desconocido");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Generar Nota de Crédito</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Generar Nota de Crédito</DialogTitle>
        </DialogHeader>
        <div className="mt-4 space-y-4">
          <div>
            <Label htmlFor="facturaId">Número de Factura</Label>
            <Input
              id="facturaId"
              type="number"
              value={facturaId}
              onChange={(e) => setFacturaId(e.target.value)}
            />
          </div>
          <div>
            <Label htmlFor="monto">Monto</Label>
            <Input
              id="monto"
              type="number"
              value={monto}
              onChange={(e) => setMonto(e.target.value)}
            />
          </div>
          <div>
            <Label htmlFor="motivo">Motivo</Label>
            <Input
              id="motivo"
              value={motivo}
              onChange={(e) => setMotivo(e.target.value)}
            />
          </div>
          <div>
            <Label>Tipo de Rubro</Label>
            <RadioGroup value={rubro} onValueChange={handleRubroChange}>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="Servicios" id="servicios" />
                <Label htmlFor="servicios">Servicios</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="Productos" id="productos" />
                <Label htmlFor="productos">Productos</Label>
              </div>
            </RadioGroup>
          </div>
          <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={() => setIsOpen(false)}>
              Cancelar
            </Button>
            <Button
              onClick={handleGenerarNotaCredito}
              disabled={isLoading || !facturaId || !monto || !motivo}
            >
              {isLoading ? "Generando..." : "Generar Nota de Crédito"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
